import React from "react";
import { useTheme } from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  const theme = useTheme();
  const primaryColor = theme.isDark ? "#3C3742" : "#e9eaeb";
  const secondaryColor = theme.isDark ? "#666171" : "#bdc2c4";

  return (
    <Svg viewBox="0 0 200 230" {...props}>
      <path d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16z" fill={primaryColor} />
      <mask id="A" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <path d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0s16 7.163 16 16z" fill="#c4c4c4" />
      </mask>
      {/* <g mask="url(#A)">
        <path
          d="M25.128 16.436c0 3.115-4.133 5.641-9.231 5.641s-9.231-2.526-9.231-5.641V15h18.461v1.436zm2.205 13.806c0-3.815-5.074-6.908-11.333-6.908S4.667 26.426 4.667 30.242V32h22.667v-1.759z"
          fill={secondaryColor}
        />
        <path
          fillRule="evenodd"
          d="M10.234 5.601C9.942 4.264 10.96 3 12.328 3c1.184 0 2.143.959 2.143 2.143v3.873l1.427-.067c.589 0 1.166.034 1.724.098V5.143c0-1.184.959-2.143 2.143-2.143 1.368 0 2.386 1.264 2.093 2.601l-.931 4.258c2.529 1.006 4.201 2.749 4.201 4.731 0 3.115-4.133 5.641-9.231 5.641s-9.231-2.526-9.231-5.641c0-2.053 1.794-3.849 4.476-4.836l-.908-4.153z"
          fill={secondaryColor}
        />
        <ellipse cx="12.308" cy="14.846" rx="1.026" ry="1.538" fill={primaryColor} />
        <ellipse cx="19.385" cy="14.846" rx="1.026" ry="1.538" fill={primaryColor} />
      </g> */}
      <path fill="#E3E3E3" opacity="1.000000" stroke="none" 
	d="
M127.143921,193.827667 
	C124.717926,194.223526 122.291931,194.619385 119.284821,194.742981 
	C118.018196,194.098404 117.264397,193.313797 116.657257,193.414383 
	C103.510376,195.592560 90.389679,196.275879 77.238731,193.370499 
	C76.637314,193.237625 75.723915,194.516861 74.955818,195.138397 
	C71.963875,194.761658 68.971939,194.384918 65.353264,193.645035 
	C63.149719,193.121765 61.572906,192.961655 60.115974,192.440002 
	C64.326042,191.264572 68.416237,190.450699 72.806412,189.577148 
	C75.726707,180.790894 78.824242,171.516953 81.885422,162.231003 
	C82.706413,159.740585 83.207932,157.125992 84.218185,154.720459 
	C86.877533,148.388245 89.992058,142.202301 89.252686,134.860016 
	C89.991730,134.408691 90.477768,134.094879 91.144760,134.072739 
	C92.824791,135.408218 94.620300,136.200058 95.764191,137.545776 
	C98.559082,140.833801 104.300484,142.141800 103.872887,147.553528 
	C103.320045,154.550339 107.102890,159.323151 110.742737,164.483490 
	C111.993088,166.256165 111.804237,169.007263 112.420158,171.271286 
	C114.008194,177.108704 115.662460,182.928406 117.351471,188.737381 
	C117.465950,189.131119 118.168915,189.315964 118.463387,189.701050 
	C121.198502,193.277618 124.155579,189.953262 127.292267,190.274506 
	C129.794357,190.723602 132.002670,190.899429 134.177948,191.423645 
	C134.077194,192.233521 134.009445,192.694992 133.536301,193.097595 
	C131.763092,193.079971 130.395294,193.121246 128.751373,193.096939 
	C128.031479,193.296799 127.587700,193.562241 127.143921,193.827667 
z"/>
<path fill="#514BE1" opacity="1.000000" stroke="none" 
	d="
M147.121338,41.194305 
	C147.121338,41.194305 147.558533,41.108925 147.818909,41.459557 
	C148.738434,42.519360 149.397583,43.228531 150.056732,43.937698 
	C150.056732,43.937698 150.021835,43.988289 150.053253,44.276237 
	C150.415115,45.029537 150.745529,45.494881 151.075958,45.960224 
	C151.075958,45.960224 150.997223,45.973011 151.012787,46.348137 
	C152.030411,47.790928 153.032440,48.858589 154.034485,49.926254 
	C154.034485,49.926254 154.041763,49.958157 154.058868,50.260193 
	C154.399673,51.023464 154.723358,51.484699 155.047043,51.945938 
	C155.047043,51.945938 155.036407,51.990341 155.037170,52.359398 
	C155.418915,53.944359 155.554794,55.330189 156.247803,56.329731 
	C156.982605,57.389549 158.246689,58.082405 159.279617,58.935524 
	C159.837234,57.907951 160.394852,56.880383 160.952469,55.852814 
	C160.629486,52.882553 160.306503,49.912292 160.132584,46.130783 
	C160.516266,44.362545 160.984222,43.396488 160.947479,42.450035 
	C160.716003,36.488033 160.572601,30.511372 160.008942,24.576702 
	C159.457886,18.774736 160.773590,17.026859 166.576431,16.995106 
	C167.409073,16.990551 168.241577,16.962692 169.074142,16.945618 
	C169.074142,16.945618 168.993027,16.969284 168.978790,17.363831 
	C168.978180,19.167883 168.991806,20.577391 169.005142,22.415695 
	C170.335770,38.561977 171.666702,54.279465 173.000946,70.334633 
	C173.327286,71.466438 173.650284,72.260551 173.974243,73.472488 
	C173.975189,75.747765 173.975189,77.605225 173.975189,79.462677 
	C173.514908,79.572807 173.054626,79.682938 172.594345,79.793068 
	C170.820251,77.386894 169.126068,74.916222 167.250412,72.592010 
	C165.223511,70.080353 163.239792,67.421013 160.710922,65.529900 
	C160.248734,65.184273 157.285599,68.237869 155.426926,69.656883 
	C155.070877,69.928703 154.480453,69.893539 153.988525,69.564697 
	C154.084930,63.805840 153.141205,58.952583 148.033295,55.532402 
	C147.702682,50.476665 147.412003,45.835487 147.121338,41.194305 
z"/>
<path fill="#7774E9" opacity="1.000000" stroke="none" 
	d="
M24.175934,146.365585 
	C22.839043,144.086884 21.174589,141.936508 20.222713,139.506958 
	C16.373158,129.681442 19.164551,119.724365 20.388680,109.599205 
	C25.443941,112.074211 36.707451,111.298225 41.029404,108.411453 
	C41.409225,111.974449 40.059288,115.964493 45.270973,116.997612 
	C46.022907,117.512039 46.507038,117.880966 46.988586,118.428192 
	C46.986008,118.606499 46.968681,118.962723 46.712662,119.240753 
	C46.159889,121.307907 45.605564,123.109177 45.689018,124.880394 
	C45.723991,125.622650 47.110847,126.301201 47.912064,127.434219 
	C47.935253,129.565704 47.929459,131.271667 47.509727,133.006638 
	C41.834618,133.773529 36.573456,134.511429 30.993626,135.294022 
	C31.395224,136.897018 31.665289,137.975006 31.942930,139.284195 
	C31.950506,139.515411 32.061909,139.964478 31.895102,140.119949 
	C29.210840,142.305466 26.693386,144.335526 24.175934,146.365585 
M37.448788,130.593430 
	C38.492931,130.563751 39.537071,130.534088 40.581215,130.504425 
	C39.931873,128.760071 39.282532,127.015709 38.633190,125.271355 
	C38.144360,125.409676 37.655533,125.548004 37.166706,125.686325 
	C37.166706,127.098473 37.166706,128.510620 37.448788,130.593430 
M39.651669,120.330795 
	C40.051266,119.189735 40.450859,118.048683 40.850452,116.907623 
	C40.306732,116.823143 39.763008,116.738663 39.219284,116.654190 
	C39.219284,117.709335 39.219284,118.764488 39.651669,120.330795 
z"/>
<path fill="#544FE2" opacity="1.000000" stroke="none" 
	d="
M22.150143,40.014584 
	C22.425598,33.096336 22.599127,26.170931 23.083841,19.267376 
	C23.158550,18.203356 24.562292,16.500292 25.525564,16.368813 
	C28.375807,15.979780 31.313257,16.229685 34.235218,16.229685 
	C34.235218,29.307119 34.235218,41.771130 34.075157,54.936043 
	C31.928593,58.371155 28.825508,60.277222 29.042681,64.517456 
	C29.147470,66.563446 27.675968,68.913231 26.377661,70.741623 
	C25.215292,72.378571 24.027361,75.271446 20.999611,72.583115 
	C21.001352,70.776672 21.001909,69.386864 21.005583,67.547943 
	C21.247169,64.505882 21.611393,61.917191 21.699287,59.319153 
	C21.916912,52.886574 22.008133,46.449718 22.150143,40.014584 
M31.986996,51.133575 
	C31.877140,50.190018 31.767284,49.246460 31.657429,48.302902 
	C31.419935,48.328606 31.182440,48.354305 30.944946,48.380005 
	C31.078329,49.813778 31.211710,51.247547 31.345095,52.681316 
	C31.535309,52.546074 31.760746,52.439522 31.902861,52.265091 
	C31.991697,52.156048 31.954950,51.944691 31.986996,51.133575 
z"/>
<path fill="#5C57E3" opacity="1.000000" stroke="none" 
	d="
M20.998426,72.999748 
	C24.027361,75.271446 25.215292,72.378571 26.377661,70.741623 
	C27.675968,68.913231 29.147470,66.563446 29.042681,64.517456 
	C28.825508,60.277222 31.928593,58.371155 33.976913,55.292744 
	C34.795399,54.684292 35.868793,54.651814 36.258606,54.119114 
	C39.368126,49.869774 42.348106,45.525642 45.688324,41.644920 
	C45.334679,48.371269 47.622002,55.015327 43.405907,61.007362 
	C42.416698,64.735100 41.789551,68.384773 41.130577,72.497940 
	C41.074886,77.973183 41.051018,82.984909 40.680481,87.955795 
	C38.672886,86.213165 36.787758,84.669907 35.432034,82.752357 
	C34.567654,81.529778 34.487583,79.752693 34.055847,78.224228 
	C32.402431,79.262451 30.569359,80.098862 29.131315,81.379021 
	C25.653961,84.474594 22.360914,87.777191 18.995848,90.629799 
	C18.374598,89.165428 17.751068,88.067596 17.127539,86.969765 
	C17.658569,82.559006 18.189598,78.148254 19.232969,73.369904 
	C20.163017,73.001465 20.580721,73.000603 20.998426,72.999748 
z"/>
<path fill="#E9EAE9" opacity="1.000000" stroke="none" 
	d="
M81.809700,47.983643 
	C82.208862,43.956097 82.608025,39.928547 83.542793,35.449581 
	C93.003448,34.997375 101.928513,34.996590 110.853561,34.995800 
	C110.903046,38.684582 110.952530,42.373367 110.780220,46.642937 
	C110.030869,47.818310 109.503304,48.412895 108.700554,49.315258 
	C108.279800,54.413734 108.134239,59.204437 107.643845,63.952950 
	C106.845680,63.711803 106.295708,63.616440 105.954086,63.297737 
	C102.779495,60.336109 100.389168,55.964020 94.778328,57.424896 
	C94.508018,57.495274 93.605515,55.140087 92.998459,53.913921 
	C92.210175,52.321716 91.422951,50.728992 90.419296,48.699829 
	C87.483696,52.538811 91.558510,57.600502 86.003227,58.544815 
	C85.997467,55.641907 85.962387,53.193043 85.996841,50.745159 
	C86.033257,48.157520 84.753586,48.099094 82.632690,48.970943 
	C82.185600,48.587818 81.997650,48.285728 81.809700,47.983643 
M104.838425,52.992153 
	C104.275169,54.422958 103.711914,55.853760 103.148659,57.284565 
	C103.923798,57.415985 104.698936,57.547405 105.474075,57.678825 
	C105.393425,56.318008 105.312775,54.957195 104.838425,52.992153 
M108.529381,41.847519 
	C108.458839,42.240993 108.388298,42.634468 108.317757,43.027943 
	C108.486160,42.728168 108.654564,42.428398 108.529381,41.847519 
z"/>
<path fill="#6F6BE8" opacity="1.000000" stroke="none" 
	d="
M176.135010,107.010674 
	C171.792831,110.018188 174.877106,114.470367 173.652145,118.908890 
	C173.221069,123.784691 173.178848,127.901070 172.975510,132.009460 
	C172.670334,138.175079 169.087280,142.529419 164.046875,142.615799 
	C163.593643,140.796432 163.157822,139.375046 162.768524,137.564804 
	C162.012314,136.432419 161.320496,135.272141 160.386078,135.023453 
	C157.575577,134.275436 154.670517,133.882660 152.020035,133.045166 
	C154.177277,131.637421 156.319244,130.734406 153.182327,128.345154 
	C152.138611,127.550194 151.264145,125.887756 151.199493,124.578102 
	C151.030624,121.157524 149.206604,120.019516 146.095490,119.700943 
	C145.955994,119.019005 145.884628,118.627678 146.145508,118.140793 
	C147.304886,117.661583 148.131989,117.277924 149.361267,116.919739 
	C154.496567,117.335365 153.922989,113.933693 154.310974,111.169876 
	C156.633545,112.976273 158.654175,114.616165 161.578903,116.989777 
	C161.468323,111.716980 163.577499,110.470520 167.126663,110.995209 
	C170.178207,111.446327 171.327332,109.913826 171.391693,107.010300 
	C172.511703,106.970299 173.258240,106.935890 174.359787,106.917587 
	C175.188202,106.959351 175.661606,106.985008 176.135010,107.010674 
M168.741333,130.797318 
	C167.317520,128.585220 165.893707,126.373116 164.469894,124.161011 
	C163.876846,124.378273 163.283813,124.595543 162.690781,124.812805 
	C163.220367,127.668182 163.749954,130.523575 164.279541,133.378967 
	C165.791626,132.757004 167.303696,132.135040 168.741333,130.797318 
M164.272812,120.484657 
	C164.012360,120.732231 163.749405,120.977478 163.505157,121.240128 
	C163.498718,121.247040 163.696091,121.443489 163.798416,121.551544 
	C164.058975,121.303642 164.319534,121.055740 164.272812,120.484657 
z"/>
<path fill="#5D59E2" opacity="1.000000" stroke="none" 
	d="
M58.189613,53.072495 
	C58.664745,48.080769 62.875206,48.886398 65.888451,48.532375 
	C68.892906,48.179375 72.016060,48.836605 75.536453,49.319489 
	C75.985741,49.588066 75.977951,50.019024 75.632889,50.024353 
	C74.700287,50.578758 73.513199,51.440777 73.612640,51.631290 
	C76.316559,56.811642 79.151978,61.923347 81.973816,67.285706 
	C81.982269,67.524857 82.001648,68.003052 81.533173,68.004059 
	C75.034470,68.006195 69.004227,68.007332 62.939209,68.045387 
	C62.904430,68.082298 62.803276,68.089783 62.855293,67.619072 
	C62.907310,61.734901 62.907310,56.321445 62.907310,50.569862 
	C61.006493,51.578201 59.598053,52.325348 58.189613,53.072495 
z"/>
<path fill="#605CE6" opacity="1.000000" stroke="none" 
	d="
M18.993570,90.996338 
	C22.360914,87.777191 25.653961,84.474594 29.131315,81.379021 
	C30.569359,80.098862 32.402431,79.262451 34.055847,78.224228 
	C34.487583,79.752693 34.567654,81.529778 35.432034,82.752357 
	C36.787758,84.669907 38.672886,86.213165 40.694092,88.330093 
	C41.049477,89.494072 41.044579,90.242897 40.586060,90.994286 
	C36.905064,91.372276 32.312584,90.551765 30.725071,92.363838 
	C27.044203,96.565376 24.815607,102.039215 21.735573,107.113068 
	C20.962170,107.490097 20.451450,107.751312 19.940731,108.012527 
	C19.016539,107.549400 18.092346,107.086273 17.331406,106.037018 
	C17.531033,101.237251 19.929747,96.763763 16.122141,92.972939 
	C16.377182,92.496590 16.632225,92.020233 17.319904,91.277283 
	C18.166216,91.005898 18.579893,91.001114 18.993570,90.996338 
z"/>
<path fill="#5954E6" opacity="1.000000" stroke="none" 
	d="
M176.467789,106.853210 
	C175.661606,106.985008 175.188202,106.959351 174.303314,106.565872 
	C171.623001,101.998566 171.260101,96.463539 165.697479,94.548508 
	C165.381226,94.439636 165.096558,93.699142 165.108200,93.260887 
	C165.229324,88.709045 162.103195,88.884529 159.000305,89.000305 
	C159.000000,89.000000 158.996948,88.999695 158.998016,88.555573 
	C158.999390,85.741066 158.999695,83.370682 159.000183,80.574890 
	C159.109558,78.685631 158.843674,77.033470 159.452621,75.820526 
	C159.899139,74.931137 161.448959,74.337402 162.593277,74.156105 
	C163.250717,74.051949 164.719650,75.012489 164.735870,75.527931 
	C164.972717,83.059441 171.849457,85.414886 175.827240,90.003685 
	C176.510223,95.049820 177.226501,100.092041 177.821106,105.148567 
	C177.876282,105.617805 177.159119,106.177856 176.467789,106.853210 
z"/>
<path fill="#9E9CF1" opacity="1.000000" stroke="none" 
	d="
M155.120728,174.019577 
	C154.420868,179.666580 152.479202,185.515732 153.356613,190.905975 
	C154.309357,196.758865 152.687103,198.828186 147.526520,199.987976 
	C144.730469,200.616394 142.129440,202.070938 139.392944,203.015137 
	C134.395370,204.739532 132.223526,203.686569 130.396301,198.296387 
	C131.448608,196.613312 132.330643,195.369858 133.396957,194.074280 
	C133.581238,194.022156 133.960342,193.964828 133.966766,193.977798 
	C133.973190,193.990768 133.977539,193.969788 134.027618,194.283081 
	C134.267227,195.175507 134.456741,195.754623 134.646255,196.333755 
	C135.272507,195.979050 135.898758,195.624344 136.606384,195.337494 
	C136.687759,195.405350 136.874649,195.305450 137.266479,195.303864 
	C138.847229,194.568390 140.036179,193.834473 141.425446,192.856613 
	C140.091476,191.627182 138.557175,190.641708 137.019684,189.525284 
	C137.016525,189.394333 137.073395,189.138596 137.382980,189.067734 
	C138.810043,188.682983 140.002472,187.942062 141.033112,188.122955 
	C149.087311,189.536484 149.466843,189.372177 151.940430,181.539093 
	C152.636169,178.743164 153.316849,176.341202 154.278137,173.962006 
	C154.746094,173.996368 154.933426,174.007980 155.120728,174.019577 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M175.895203,89.541183 
	C171.849457,85.414886 164.972717,83.059441 164.735870,75.527931 
	C164.719650,75.012489 163.250717,74.051949 162.593277,74.156105 
	C161.448959,74.337402 159.899139,74.931137 159.452621,75.820526 
	C158.843674,77.033470 159.109558,78.685631 158.615509,80.588554 
	C157.168015,82.906311 155.927216,84.723969 155.239578,86.731125 
	C155.139282,87.023872 157.678879,88.221031 158.996948,88.999695 
	C158.996948,88.999695 159.000000,89.000000 158.999847,89.333176 
	C158.999756,90.110184 158.999802,90.554024 158.600067,90.998169 
	C157.134338,91.000710 156.068390,91.002945 154.884033,90.792442 
	C154.562180,90.315247 154.304977,90.122490 153.975403,89.533279 
	C153.926926,83.050636 153.897049,77.036148 153.901016,70.766388 
	C153.955948,70.340736 153.977066,70.170372 153.998169,70.000000 
	C154.480453,69.893539 155.070877,69.928703 155.426926,69.656883 
	C157.285599,68.237869 160.248734,65.184273 160.710922,65.529900 
	C163.239792,67.421013 165.223511,70.080353 167.250412,72.592010 
	C169.126068,74.916222 170.820251,77.386894 172.594345,79.793068 
	C173.054626,79.682938 173.514908,79.572807 173.975189,79.462677 
	C173.975189,77.605225 173.975189,75.747765 174.198120,73.455353 
	C174.421066,73.020409 174.869293,73.001503 174.869293,73.001503 
	C175.233902,78.360565 175.598526,83.719627 175.895203,89.541183 
z"/>
<path fill="#DCDCDC" opacity="1.000000" stroke="none" 
	d="
M75.101761,195.470657 
	C75.723915,194.516861 76.637314,193.237625 77.238731,193.370499 
	C90.389679,196.275879 103.510376,195.592560 116.657257,193.414383 
	C117.264397,193.313797 118.018196,194.098404 118.872894,194.789734 
	C104.570770,203.339981 89.967087,203.220657 75.101761,195.470657 
z"/>
<path fill="#A4A1F0" opacity="1.000000" stroke="none" 
	d="
M62.095543,199.113846 
	C61.698612,203.255493 59.954170,205.010025 55.584156,203.332428 
	C51.267262,201.675232 46.633343,200.769669 42.473488,198.819748 
	C41.105068,198.178299 40.329800,195.366257 40.072586,193.441299 
	C39.472073,188.947220 39.322308,184.390854 39.049355,179.856155 
	C38.950336,178.211136 39.020298,176.555939 39.019203,174.221970 
	C39.025246,173.538544 39.066761,173.050613 39.313992,173.146851 
	C40.026386,173.504227 40.491550,173.765366 40.963486,174.459076 
	C42.001369,179.601364 43.032482,184.311081 43.938240,189.332870 
	C44.168793,190.436234 44.346504,191.735504 44.910568,191.933380 
	C47.783077,192.940994 50.755543,193.663681 53.983345,194.673676 
	C56.880959,196.278793 59.488251,197.696320 62.095543,199.113846 
z"/>
<path fill="#625EE0" opacity="1.000000" stroke="none" 
	d="
M114.020035,49.187504 
	C119.509346,48.501431 124.998650,47.815357 130.836411,47.494217 
	C130.444351,49.243946 129.703857,50.628746 128.645142,52.072296 
	C128.723389,54.094135 128.574982,56.440880 129.630493,57.939926 
	C131.785965,61.001152 131.148026,62.859428 127.944618,63.978542 
	C123.680000,65.468399 119.316673,66.675705 114.662231,68.004028 
	C113.883987,67.999191 113.439262,67.994720 113.157410,67.683731 
	C116.244400,61.720921 119.168510,56.064629 122.071793,50.448624 
	C119.165398,49.993404 116.592712,49.590454 114.020035,49.187504 
z"/>
<path fill="#2C25D1" opacity="1.000000" stroke="none" 
	d="
M128.963364,52.013550 
	C129.703857,50.628746 130.444351,49.243946 131.248230,47.510048 
	C132.550995,47.791592 134.060196,48.162846 134.970612,49.109795 
	C136.596497,50.800930 137.861084,52.839455 139.632233,55.184792 
	C141.747681,59.266327 142.788345,63.583977 148.211945,64.199417 
	C149.316757,66.627380 150.159607,68.814758 150.994202,71.349480 
	C150.338654,72.468300 149.691345,73.239784 149.032455,73.626709 
	C147.719986,71.076569 146.650986,68.717453 145.051483,66.801117 
	C143.525192,64.972481 141.446167,63.605175 139.061569,61.565960 
	C137.511703,62.731457 135.319870,64.379707 133.063110,65.611633 
	C132.456039,60.555653 132.075790,55.866455 128.963364,52.013550 
z"/>
<path fill="#221E99" opacity="1.000000" stroke="none" 
	d="
M43.767960,60.929298 
	C47.622002,55.015327 45.334679,48.371269 46.032936,41.625084 
	C49.328152,40.969498 53.815609,44.955605 54.014217,48.398743 
	C54.157410,50.881275 54.056118,53.377911 53.776131,56.568188 
	C53.311230,58.493008 53.134068,59.717896 52.670158,61.108406 
	C50.282478,62.822556 48.181545,64.371086 46.063011,65.546883 
	C45.286263,63.759205 44.527111,62.344254 43.767960,60.929298 
z"/>
<path fill="#6866E2" opacity="1.000000" stroke="none" 
	d="
M20.009998,108.253311 
	C20.451450,107.751312 20.962170,107.490097 22.149361,107.114639 
	C24.863766,106.356140 27.976597,106.320267 28.747198,104.958725 
	C31.569500,99.972153 36.576878,98.486900 41.016045,96.459900 
	C41.025543,100.611847 41.018272,104.303391 41.011002,107.994934 
	C36.707451,111.298225 25.443941,112.074211 20.504452,109.184738 
	C20.150312,108.820511 20.114790,108.657303 20.009998,108.253311 
z"/>
<path fill="#817DED" opacity="1.000000" stroke="none" 
	d="
M30.166031,166.009705 
	C28.842997,161.527496 27.519960,157.045288 26.549267,152.295166 
	C31.016182,155.136536 33.088154,154.882568 35.556217,151.104645 
	C36.596359,157.807693 37.307335,164.412857 37.750484,171.277679 
	C34.177074,172.608047 32.104763,171.900162 31.885695,167.965897 
	C31.847725,167.284027 30.765774,166.660294 30.166031,166.009705 
z"/>
<path fill="#3E38DF" opacity="1.000000" stroke="none" 
	d="
M52.956909,60.942783 
	C53.134068,59.717896 53.311230,58.493008 53.757607,57.008087 
	C55.308922,55.705284 56.591015,54.662514 58.031361,53.346119 
	C59.598053,52.325348 61.006493,51.578201 62.907310,50.569862 
	C62.907310,56.321445 62.907310,61.734901 62.416550,67.590210 
	C58.289707,68.012306 54.653625,67.992561 51.064552,67.611237 
	C51.726681,65.147369 52.341797,63.045074 52.956909,60.942783 
z"/>
<path fill="#524ED5" opacity="1.000000" stroke="none" 
	d="
M113.786728,49.109787 
	C116.592712,49.590454 119.165398,49.993404 122.071793,50.448624 
	C119.168510,56.064629 116.244400,61.720921 112.804947,67.696365 
	C111.819885,68.040001 111.350159,68.064491 110.667633,68.046738 
	C110.454834,68.004494 110.021561,67.981049 109.981216,67.524963 
	C109.878387,63.701679 109.815895,60.334492 109.834564,56.556740 
	C110.316872,53.480236 108.843803,49.988506 113.062363,49.004532 
	C113.062363,49.004532 113.553413,49.032074 113.786728,49.109787 
z"/>
<path fill="#201C9A" opacity="1.000000" stroke="none" 
	d="
M147.949951,63.958828 
	C142.788345,63.583977 141.747681,59.266327 139.956635,55.265373 
	C139.981689,53.159187 140.012039,51.423172 140.099152,49.690014 
	C140.311630,45.462662 141.613083,42.016289 146.751160,41.157303 
	C147.412003,45.835487 147.702682,50.476665 147.999054,55.980278 
	C147.986511,59.214745 147.968231,61.586788 147.949951,63.958828 
z"/>
<path fill="#8584EA" opacity="1.000000" stroke="none" 
	d="
M155.547424,174.016312 
	C154.933426,174.007980 154.746094,173.996368 154.312134,173.670227 
	C154.394547,172.892868 154.723557,172.429993 155.406754,171.882080 
	C156.483627,170.052078 157.392258,168.358551 157.891541,166.551895 
	C159.052704,162.350143 160.016525,158.093872 161.505219,153.900360 
	C163.489059,153.868591 165.027969,153.796173 166.566895,153.723755 
	C165.717636,159.144699 164.820786,164.558731 164.032684,169.988556 
	C163.265793,175.272324 159.199692,173.757675 155.547424,174.016312 
z"/>
<path fill="#3833C9" opacity="1.000000" stroke="none" 
	d="
M35.227051,151.006760 
	C33.088154,154.882568 31.016182,155.136536 26.551197,151.912292 
	C25.594896,150.180267 24.989010,148.563202 24.279530,146.655853 
	C26.693386,144.335526 29.210840,142.305466 32.136391,140.128937 
	C32.544483,139.982468 33.027946,140.003708 33.137512,140.215607 
	C33.440521,140.692215 33.689827,140.884521 34.117699,141.284103 
	C34.815899,142.378906 35.391392,143.194031 36.000961,144.372223 
	C36.058620,144.977325 36.082203,145.219330 35.879776,145.741425 
	C35.511524,147.683243 35.369286,149.345001 35.227051,151.006760 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M172.997635,69.996948 
	C171.666702,54.279465 170.335770,38.561977 169.314545,22.425034 
	C170.036911,22.018986 170.449570,22.032396 170.862213,22.045809 
	C172.238617,37.739395 173.615005,53.432980 174.612183,69.562500 
	C173.821167,69.997940 173.409409,69.997444 172.997635,69.996948 
z"/>
<path fill="#EFEFEF" opacity="1.000000" stroke="none" 
	d="
M110.811508,34.614971 
	C101.928513,34.996590 93.003448,34.997375 83.619995,34.996716 
	C84.067810,33.995071 84.943336,32.162777 85.885162,32.128040 
	C92.623177,31.879526 99.379585,31.859825 106.118286,32.100037 
	C107.692032,32.156136 109.220276,33.488811 110.811508,34.614971 
z"/>
<path fill="#7975E8" opacity="1.000000" stroke="none" 
	d="
M164.064270,143.013763 
	C169.087280,142.529419 172.670334,138.175079 172.975510,132.009460 
	C173.178848,127.901070 173.221069,123.784691 173.586716,119.345688 
	C176.168961,128.234100 176.301788,137.094971 168.987350,144.914551 
	C167.555481,144.934219 166.814377,144.907852 165.737534,144.841187 
	C164.959702,144.714020 164.517609,144.627136 164.071747,144.285461 
	C164.066742,143.691696 164.065506,143.352722 164.064270,143.013763 
z"/>
<path fill="#6D68B9" opacity="1.000000" stroke="none" 
	d="
M168.857483,16.589886 
	C168.241577,16.962692 167.409073,16.990551 166.576431,16.995106 
	C160.773590,17.026859 159.457886,18.774736 160.008942,24.576702 
	C160.572601,30.511372 160.716003,36.488033 160.947479,42.450035 
	C160.984222,43.396488 160.516266,44.362545 160.096756,45.702709 
	C159.911865,46.085880 159.438721,46.013794 159.233978,45.476730 
	C159.006561,35.948158 158.983902,26.956652 158.961243,17.965147 
	C161.607773,14.144902 165.360229,16.508894 168.857483,16.589886 
z"/>
<path fill="#5C57E3" opacity="1.000000" stroke="none" 
	d="
M21.943752,40.381760 
	C22.008133,46.449718 21.916912,52.886574 21.699287,59.319153 
	C21.611393,61.917191 21.247169,64.505882 20.694204,67.548256 
	C19.966459,67.998604 19.553205,67.999527 19.139954,68.000443 
	C20.005756,58.916607 20.871559,49.832767 21.943752,40.381760 
z"/>
<path fill="#3933CC" opacity="1.000000" stroke="none" 
	d="
M166.073288,144.881500 
	C166.814377,144.907852 167.555481,144.934219 168.655899,145.055267 
	C168.335754,147.870728 167.656281,150.591537 166.771851,153.518036 
	C165.027969,153.796173 163.489059,153.868591 161.478012,153.670441 
	C161.005890,153.399857 160.940887,152.939240 160.968719,152.583679 
	C162.688797,149.779236 164.381042,147.330368 166.073288,144.881500 
z"/>
<path fill="#4239D1" opacity="1.000000" stroke="none" 
	d="
M62.361694,198.968689 
	C59.488251,197.696320 56.880959,196.278793 54.119198,194.386688 
	C54.935410,192.618240 55.906090,191.324356 57.034996,190.250427 
	C57.741856,190.894272 58.290489,191.318161 58.954891,192.006729 
	C59.379135,192.448120 59.687614,192.624832 59.996094,192.801544 
	C61.572906,192.961655 63.149719,193.121765 64.922333,193.630951 
	C64.288033,195.594513 63.457939,197.209030 62.361694,198.968689 
z"/>
<path fill="#4C47BD" opacity="1.000000" stroke="none" 
	d="
M75.977951,50.019024 
	C75.977951,50.019024 75.985741,49.588066 75.983383,49.372570 
	C77.699585,48.877697 79.418129,48.598324 81.473190,48.151295 
	C81.997650,48.285728 82.185600,48.587818 82.705223,49.379894 
	C83.308571,53.575806 83.580238,57.281731 83.630127,61.305153 
	C83.264534,63.772022 83.120712,65.921394 82.732826,68.057503 
	C82.488762,68.044235 82.001648,68.003052 82.001648,68.003052 
	C82.001648,68.003052 81.982269,67.524857 81.987564,66.812744 
	C81.998787,62.665501 81.698029,59.195518 82.083382,55.803482 
	C82.644554,50.863693 80.548676,49.270927 75.977951,50.019024 
z"/>
<path fill="#8C8AED" opacity="1.000000" stroke="none" 
	d="
M30.091206,166.419968 
	C30.765774,166.660294 31.847725,167.284027 31.885695,167.965897 
	C32.104763,171.900162 34.177074,172.608047 37.986736,171.458130 
	C38.682796,171.936157 38.874779,172.493378 39.066761,173.050613 
	C39.066761,173.050613 39.025246,173.538544 38.988327,173.780243 
	C30.596691,173.992477 30.169374,173.648529 30.091206,166.419968 
z"/>
<path fill="#F5F5F7" opacity="1.000000" stroke="none" 
	d="
M158.565613,18.156342 
	C158.983902,26.956652 159.006561,35.948158 159.008209,45.397392 
	C158.657272,44.750221 158.046036,43.646229 158.042465,42.540276 
	C158.016388,34.476254 158.112488,26.411831 158.565613,18.156342 
z"/>
<path fill="#6561E5" opacity="1.000000" stroke="none" 
	d="
M16.061832,93.432793 
	C19.929747,96.763763 17.531033,101.237251 17.201670,105.747879 
	C16.606298,101.994133 16.303911,97.943390 16.061832,93.432793 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M160.590912,55.896606 
	C160.394852,56.880383 159.837234,57.907951 159.279617,58.935524 
	C158.246689,58.082405 156.982605,57.389549 156.247803,56.329731 
	C155.554794,55.330189 155.418915,53.944359 155.057373,52.348713 
	C156.794327,53.292778 158.511841,54.616589 160.590912,55.896606 
z"/>
<path fill="#DBDBFA" opacity="1.000000" stroke="none" 
	d="
M113.043716,48.650009 
	C108.843803,49.988506 110.316872,53.480236 109.610779,56.237396 
	C109.195808,53.888237 109.085777,51.447861 108.975754,49.007484 
	C109.503304,48.412895 110.030869,47.818310 110.835793,47.099312 
	C111.750458,47.415092 112.387756,47.855289 113.043716,48.650009 
z"/>
<path fill="#2C2690" opacity="1.000000" stroke="none" 
	d="
M133.212677,194.126404 
	C132.330643,195.369858 131.448608,196.613312 130.239685,197.956635 
	C129.149200,196.792542 128.385574,195.528580 127.382935,194.046143 
	C127.587700,193.562241 128.031479,193.296799 129.018723,193.349579 
	C130.779022,193.820679 131.995850,193.973557 133.212677,194.126404 
z"/>
<path fill="#605CE6" opacity="1.000000" stroke="none" 
	d="
M19.072628,68.413452 
	C19.553205,67.999527 19.966459,67.998604 20.691090,67.997375 
	C21.001909,69.386864 21.001352,70.776672 20.999611,72.583115 
	C20.580721,73.000603 20.163017,73.001465 19.433922,73.001678 
	C19.083456,71.609505 19.044380,70.217979 19.072628,68.413452 
z"/>
<path fill="#6D68B9" opacity="1.000000" stroke="none" 
	d="
M170.934647,21.667454 
	C170.449570,22.032396 170.036911,22.018986 169.314850,21.996235 
	C168.991806,20.577391 168.978180,19.167883 169.011856,17.337440 
	C169.708481,18.374035 170.357773,19.831566 170.934647,21.667454 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M153.919525,49.623108 
	C153.032440,48.858589 152.030411,47.790928 151.050354,46.332241 
	C151.983078,47.067467 152.893829,48.193718 153.919525,49.623108 
z"/>
<path fill="#6561E5" opacity="1.000000" stroke="none" 
	d="
M18.995848,90.629791 
	C18.579893,91.001114 18.166216,91.005898 17.442202,91.015434 
	C17.088192,89.938477 17.044519,88.856758 17.064192,87.372406 
	C17.751068,88.067596 18.374598,89.165428 18.995848,90.629791 
z"/>
<path fill="#5954E6" opacity="1.000000" stroke="none" 
	d="
M173.000946,70.334633 
	C173.409409,69.997444 173.821167,69.997940 174.541748,69.999985 
	C174.895157,70.752678 174.939758,71.503838 174.926819,72.628250 
	C174.869293,73.001503 174.421066,73.020409 174.197174,73.037537 
	C173.650284,72.260551 173.327286,71.466438 173.000946,70.334633 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M150.039398,43.611946 
	C149.397583,43.228531 148.738434,42.519360 148.036652,41.506378 
	C148.670029,41.897110 149.346054,42.591648 150.039398,43.611946 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M155.025787,51.639893 
	C154.723358,51.484699 154.399673,51.023464 154.074615,50.264595 
	C154.383698,50.422596 154.694107,50.878223 155.025787,51.639893 
z"/>
<path fill="#5652E1" opacity="1.000000" stroke="none" 
	d="
M151.043488,45.653076 
	C150.745529,45.494881 150.415115,45.029537 150.080994,44.262962 
	C150.388550,44.423126 150.699783,44.884525 151.043488,45.653076 
z"/>
<path fill="#DFDFDF" opacity="1.000000" stroke="none" 
	d="
M60.115974,192.440002 
	C59.687614,192.624832 59.379135,192.448120 59.016045,191.579041 
	C58.210285,184.211151 57.459137,177.535599 56.739807,170.443573 
	C56.719902,168.071121 56.668179,166.115143 56.737595,163.742706 
	C56.487564,160.538879 56.116402,157.751541 55.768410,154.526306 
	C55.722015,151.506302 55.652447,148.924210 55.749767,145.985626 
	C57.627769,144.554047 59.259556,143.314133 61.068939,142.443192 
	C63.659756,141.196075 66.380310,140.218491 69.457481,139.109985 
	C71.631546,138.751984 73.400742,138.442657 75.154266,138.061279 
	C79.773041,137.056747 84.385017,136.020905 88.999695,134.997513 
	C89.992058,142.202301 86.877533,148.388245 84.218185,154.720459 
	C83.207932,157.125992 82.706413,159.740585 81.885422,162.231003 
	C78.824242,171.516953 75.726707,180.790894 72.806412,189.577148 
	C68.416237,190.450699 64.326042,191.264572 60.115974,192.440002 
z"/>
<path fill="#DFDFDF" opacity="1.000000" stroke="none" 
	d="
M126.998474,190.001221 
	C124.155579,189.953262 121.198502,193.277618 118.463387,189.701050 
	C118.168915,189.315964 117.465950,189.131119 117.351471,188.737381 
	C115.662460,182.928406 114.008194,177.108704 112.420158,171.271286 
	C111.804237,169.007263 111.993088,166.256165 110.742737,164.483490 
	C107.102890,159.323151 103.320045,154.550339 103.872887,147.553528 
	C104.300484,142.141800 98.559082,140.833801 95.764191,137.545776 
	C94.620300,136.200058 92.824791,135.408218 91.447754,133.835266 
	C95.115273,133.175934 98.835121,132.298080 102.168930,133.075531 
	C108.560829,134.566147 114.733536,136.996658 120.933365,139.370056 
	C116.179832,144.744095 116.314774,144.641388 120.590050,150.258774 
	C121.890694,151.967728 122.339256,154.380127 122.961571,156.532928 
	C123.456146,158.243820 123.392288,160.129135 123.970726,161.800522 
	C126.723549,169.754700 129.611252,177.662170 132.341965,185.282150 
	C130.153549,187.214844 128.576019,188.608032 126.998474,190.001221 
z"/>
<path fill="#DDDDDD" opacity="1.000000" stroke="none" 
	d="
M127.292267,190.274506 
	C128.576019,188.608032 130.153549,187.214844 132.341965,185.282150 
	C129.611252,177.662170 126.723549,169.754700 123.970726,161.800522 
	C123.392288,160.129135 123.456146,158.243820 122.961571,156.532928 
	C122.339256,154.380127 121.890694,151.967728 120.590050,150.258774 
	C116.314774,144.641388 116.179832,144.744095 121.223770,139.382172 
	C122.063248,139.339081 122.548508,139.616699 123.059647,140.199097 
	C123.765686,140.603973 124.445847,140.704056 125.526932,140.829224 
	C129.926300,141.938995 133.924759,143.023712 138.040482,144.410217 
	C138.258377,144.903259 138.358994,145.094543 138.415405,145.572174 
	C138.321838,146.242142 138.272461,146.625717 137.893219,147.140625 
	C137.671051,148.832123 137.778748,150.392319 137.842529,152.164703 
	C137.798630,152.376923 137.749695,152.807571 137.563080,153.160019 
	C136.600662,165.995178 135.824875,178.477905 134.840240,190.994263 
	C134.631393,191.027924 134.210968,191.075256 134.210968,191.075256 
	C132.002670,190.899429 129.794357,190.723602 127.292267,190.274506 
M133.101089,189.240875 
	C133.101089,189.240875 133.423599,189.255295 133.423599,189.255295 
	C133.423599,189.255295 133.002762,188.997910 132.757553,188.625290 
	C132.439255,188.634109 132.120972,188.642929 131.802673,188.651764 
	C132.201080,188.768799 132.599503,188.885834 133.101089,189.240875 
z"/>
<path fill="#B2B2B1" opacity="1.000000" stroke="none" 
	d="
M137.923203,144.108444 
	C133.924759,143.023712 129.926300,141.938995 125.317047,140.623718 
	C124.148743,140.226883 123.591255,140.060608 123.033768,139.894333 
	C122.548508,139.616699 122.063248,139.339081 121.287582,139.049316 
	C114.733536,136.996658 108.560829,134.566147 102.168930,133.075531 
	C98.835121,132.298080 95.115273,133.175934 91.266800,133.543579 
	C90.477768,134.094879 89.991730,134.408691 89.252686,134.860016 
	C84.385017,136.020905 79.773041,137.056747 75.154266,138.061279 
	C73.400742,138.442657 71.631546,138.751984 69.442894,138.711258 
	C69.018158,137.273651 69.020020,136.218948 69.389084,135.102524 
	C72.513611,133.714447 75.270920,132.388077 78.378098,131.029633 
	C79.625092,130.121506 80.522232,129.245468 81.830627,128.350555 
	C85.241776,128.552032 88.241661,128.772354 91.631531,129.006805 
	C96.016548,128.680801 100.011574,128.340637 104.316391,127.895630 
	C105.474930,127.923157 106.376091,127.915192 107.164299,128.209610 
	C115.092415,131.170990 123.002007,134.181961 131.161789,137.418961 
	C133.578918,139.808777 135.751053,141.958603 137.923203,144.108444 
z"/>
<path fill="#9E9CF1" opacity="1.000000" stroke="none" 
	d="
M134.177948,191.423645 
	C134.210968,191.075256 134.631393,191.027924 135.067871,191.065308 
	C135.504364,191.102676 135.951294,191.268982 135.993973,191.600449 
	C135.723282,192.728821 135.409927,193.525742 134.803513,194.276611 
	C134.332809,194.143631 134.155167,194.056717 133.977539,193.969788 
	C133.977539,193.969788 133.973190,193.990768 133.952667,193.775940 
	C133.932144,193.561096 133.941696,193.156464 133.941711,193.156464 
	C134.009445,192.694992 134.077194,192.233521 134.177948,191.423645 
z"/>
<path fill="#0D0A66" opacity="1.000000" stroke="none" 
	d="
M133.536285,193.097595 
	C133.941696,193.156464 133.932144,193.561096 133.946243,193.762970 
	C133.960342,193.964828 133.581238,194.022156 133.396957,194.074280 
	C131.995850,193.973557 130.779022,193.820679 129.294846,193.415161 
	C130.395294,193.121246 131.763092,193.079971 133.536285,193.097595 
z"/>
<path fill="#332ECE" opacity="1.000000" stroke="none" 
	d="
M148.211945,64.199417 
	C147.968231,61.586788 147.986511,59.214745 148.039001,56.394833 
	C153.141205,58.952583 154.084930,63.805840 153.988525,69.564697 
	C153.977066,70.170372 153.955948,70.340736 153.545502,70.759979 
	C152.438248,71.006615 151.720352,71.004379 151.002441,71.002136 
	C150.159607,68.814758 149.316757,66.627380 148.211945,64.199417 
z"/>
<path fill="#281CE3" opacity="1.000000" stroke="none" 
	d="
M51.017540,67.972824 
	C54.653625,67.992561 58.289707,68.012306 62.364532,68.060913 
	C62.803276,68.089783 62.904430,68.082298 62.922501,68.494209 
	C62.957470,71.603539 62.974369,74.300949 62.959465,77.441689 
	C62.943069,80.255554 62.958473,82.626106 62.936577,85.395538 
	C62.929825,86.860596 62.960377,87.926773 62.945763,89.441849 
	C62.925537,92.593163 62.950470,95.295570 62.924381,98.424179 
	C62.909847,100.563942 62.946331,102.277519 62.926346,104.442200 
	C62.771141,107.611969 62.672401,110.330635 62.480064,113.151474 
	C62.281101,113.538681 62.341705,113.789627 62.660618,114.031387 
	C62.752968,114.056290 62.941940,114.026527 62.882172,114.384583 
	C62.734299,115.461220 62.646194,116.179817 62.170097,116.925560 
	C59.527264,117.257286 57.272423,117.561859 54.739746,117.955460 
	C54.279053,118.110756 54.096191,118.177040 53.514729,118.179001 
	C51.769760,118.154099 50.423393,118.193520 48.732018,118.162781 
	C47.921730,118.145058 47.456451,118.197472 46.991173,118.249893 
	C46.507038,117.880966 46.022907,117.512039 45.268913,116.543388 
	C44.574631,109.178787 44.150208,102.413902 43.758530,95.311188 
	C43.747509,94.051735 43.703743,93.130112 43.990704,91.919189 
	C44.215958,85.086288 44.110489,78.542686 44.005577,71.626266 
	C44.008614,70.507767 44.011089,69.762100 44.406776,69.002022 
	C46.872505,68.649353 48.945023,68.311089 51.017540,67.972824 
z"/>
<path fill="#A1A3A7" opacity="1.000000" stroke="none" 
	d="
M47.923668,132.977646 
	C47.929459,131.271667 47.935253,129.565704 47.990116,127.165024 
	C48.080700,126.288368 48.122215,126.106422 48.517303,125.961288 
	C50.609642,125.995903 52.348412,125.993698 54.375771,126.144226 
	C56.101925,127.233086 57.539494,128.169220 59.099434,129.377991 
	C59.825005,130.426346 60.428207,131.202057 61.088081,132.320648 
	C62.114513,133.147369 63.084274,133.631226 64.017487,134.349823 
	C63.980938,134.584579 63.847271,135.040558 63.514160,135.122314 
	C61.157997,138.866486 57.971142,140.271744 53.830627,139.768997 
	C53.750092,139.554413 53.638535,139.109802 53.949497,138.898834 
	C56.242489,134.704330 52.568882,133.754242 50.298473,130.846100 
	C50.156811,133.254074 50.074493,134.653275 49.742889,135.933426 
	C49.046394,135.495163 48.599190,135.175949 48.130630,134.541595 
	C48.047405,133.810181 47.985535,133.393921 47.923668,132.977646 
z"/>
<path fill="#3731CF" opacity="1.000000" stroke="none" 
	d="
M43.725784,95.649033 
	C44.150208,102.413902 44.574631,109.178787 45.001114,116.397888 
	C40.059288,115.964493 41.409225,111.974449 41.029404,108.411453 
	C41.018272,104.303391 41.025543,100.611847 41.360245,96.417923 
	C42.367050,95.826706 43.046417,95.737869 43.725784,95.649033 
z"/>
<path fill="#2F27D9" opacity="1.000000" stroke="none" 
	d="
M33.027946,140.003708 
	C33.027946,140.003708 32.544483,139.982468 32.303196,139.973480 
	C32.061909,139.964478 31.950506,139.515411 32.212109,139.151230 
	C32.977516,138.190521 33.481323,137.593979 34.302994,137.165253 
	C36.637287,138.228180 38.691841,139.047516 40.660526,140.037598 
	C43.146370,141.287720 45.562206,142.677032 47.675472,144.007767 
	C46.899269,144.010254 46.455830,144.011307 45.557152,144.008118 
	C42.056900,144.005661 39.011890,144.007401 35.966885,144.009155 
	C35.391392,143.194031 34.815899,142.378906 34.006771,141.072845 
	C33.580509,140.317307 33.332111,140.124573 33.027946,140.003708 
z"/>
<path fill="#2E28BC" opacity="1.000000" stroke="none" 
	d="
M33.985126,136.997452 
	C33.481323,137.593979 32.977516,138.190521 32.204533,138.920013 
	C31.665289,137.975006 31.395224,136.897018 30.993626,135.294022 
	C36.573456,134.511429 41.834618,133.773529 47.509727,133.006638 
	C47.985535,133.393921 48.047405,133.810181 48.069214,134.600555 
	C48.029148,134.974655 47.907131,135.092957 47.474472,135.062622 
	C42.689587,135.687347 38.337357,136.342407 33.985126,136.997452 
z"/>
<path fill="#7977E3" opacity="1.000000" stroke="none" 
	d="
M37.307747,130.258087 
	C37.166706,128.510620 37.166706,127.098473 37.166706,125.686325 
	C37.655533,125.548004 38.144360,125.409676 38.633190,125.271355 
	C39.282532,127.015709 39.931873,128.760071 40.581215,130.504425 
	C39.537071,130.534088 38.492931,130.563751 37.307747,130.258087 
z"/>
<path fill="#7977E3" opacity="1.000000" stroke="none" 
	d="
M48.163734,125.924477 
	C48.122215,126.106422 48.080700,126.288368 47.961136,126.739517 
	C47.110847,126.301201 45.723991,125.622650 45.689018,124.880394 
	C45.605564,123.109177 46.159889,121.307907 46.794495,119.305191 
	C47.132347,119.091606 47.297874,119.216286 47.388298,119.565010 
	C47.707062,121.917313 47.935398,123.920898 48.163734,125.924477 
z"/>
<path fill="#7977E3" opacity="1.000000" stroke="none" 
	d="
M39.435478,120.075211 
	C39.219284,118.764488 39.219284,117.709335 39.219284,116.654190 
	C39.763008,116.738663 40.306732,116.823143 40.850452,116.907623 
	C40.450859,118.048683 40.051266,119.189735 39.435478,120.075211 
z"/>
<path fill="#7977E3" opacity="1.000000" stroke="none" 
	d="
M46.988590,118.428192 
	C47.456451,118.197472 47.921730,118.145058 48.553955,118.351677 
	C48.246563,118.812576 47.772221,119.014435 47.297874,119.216293 
	C47.297874,119.216286 47.132347,119.091606 47.050514,119.027161 
	C46.968681,118.962723 46.986008,118.606499 46.988590,118.428192 
z"/>
<path fill="#5C57E3" opacity="1.000000" stroke="none" 
	d="
M31.980778,51.456413 
	C31.954950,51.944691 31.991697,52.156048 31.902861,52.265091 
	C31.760746,52.439522 31.535309,52.546074 31.345093,52.681316 
	C31.211710,51.247547 31.078329,49.813778 30.944946,48.380005 
	C31.182440,48.354305 31.419935,48.328606 31.657429,48.302902 
	C31.767284,49.246460 31.877140,50.190018 31.980778,51.456413 
z"/>
<path fill="#2E27D2" opacity="1.000000" stroke="none" 
	d="
M44.005020,71.999084 
	C44.110489,78.542686 44.215958,85.086288 43.691330,91.904892 
	C42.387383,91.783836 41.713531,91.387772 41.039680,90.991714 
	C41.044579,90.242897 41.049477,89.494072 41.040764,88.370949 
	C41.051018,82.984909 41.074886,77.973183 41.482727,72.488022 
	C42.579475,72.009430 43.292248,72.004257 44.005020,71.999084 
z"/>
<path fill="#3731CF" opacity="1.000000" stroke="none" 
	d="
M44.005577,71.626266 
	C43.292248,72.004257 42.579475,72.009430 41.514553,72.024521 
	C41.789551,68.384773 42.416698,64.735100 43.405903,61.007362 
	C44.527111,62.344254 45.286263,63.759205 45.772385,65.710503 
	C45.004093,67.170044 44.508827,68.093239 44.013565,69.016426 
	C44.011089,69.762100 44.008614,70.507767 44.005577,71.626266 
z"/>
<path fill="#EEEFF3" opacity="1.000000" stroke="none" 
	d="
M108.700562,49.315262 
	C109.085777,51.447861 109.195808,53.888237 109.529617,56.647957 
	C109.815895,60.334492 109.878387,63.701679 109.690826,67.827225 
	C109.629791,75.392281 109.818810,82.198975 109.972443,89.420670 
	C109.985405,91.221474 110.033752,92.607277 110.021179,94.407234 
	C109.999901,96.213387 110.039543,97.605385 110.021027,99.394760 
	C109.997063,100.860664 110.031258,101.929192 110.013603,103.433052 
	C109.916435,105.910957 109.871101,107.953537 109.652534,110.111099 
	C109.267403,110.412346 109.103661,110.634178 108.521439,110.927429 
	C101.465576,110.978333 94.872421,110.864517 88.288834,111.065048 
	C85.193855,111.159309 83.586273,110.217499 83.841148,106.511871 
	C83.879189,97.695259 83.859459,89.343208 83.884834,80.525360 
	C83.903931,73.702263 83.877922,67.344955 83.851906,60.987656 
	C83.580238,57.281731 83.308571,53.575806 82.964371,49.460930 
	C84.753586,48.099094 86.033257,48.157520 85.996841,50.745159 
	C85.962387,53.193043 85.997467,55.641907 86.001663,59.012909 
	C86.001366,65.238983 86.001366,70.542480 86.001366,75.766502 
	C94.231010,77.981163 88.392632,86.665916 91.923546,89.815674 
	C94.534866,88.616615 96.449463,86.956291 97.667953,87.366035 
	C99.379631,87.941628 100.574234,90.054909 102.136543,91.661224 
	C102.902283,89.796165 103.283096,87.030296 104.259361,86.801125 
	C109.135475,85.656517 107.941887,82.062195 107.980217,78.951263 
	C108.041649,73.966614 107.991600,68.980583 107.988678,63.995136 
	C108.134239,59.204437 108.279800,54.413734 108.700562,49.315262 
M88.944267,92.069679 
	C88.072144,91.748405 87.200012,91.427132 86.327888,91.105858 
	C86.327888,94.986588 86.327888,98.867310 86.327888,102.748039 
	C87.214912,102.737686 88.101944,102.727333 88.988968,102.716980 
	C88.988968,99.457130 88.988968,96.197273 88.944267,92.069679 
z"/>
<path fill="#ECECEC" opacity="1.000000" stroke="none" 
	d="
M107.643837,63.952950 
	C107.991600,68.980583 108.041649,73.966614 107.980217,78.951263 
	C107.941887,82.062195 109.135475,85.656517 104.259361,86.801125 
	C103.283096,87.030296 102.902283,89.796165 102.136543,91.661224 
	C100.574234,90.054909 99.379631,87.941628 97.667953,87.366035 
	C96.449463,86.956291 94.534866,88.616615 91.923546,89.815674 
	C88.392632,86.665916 94.231010,77.981163 86.001366,75.766502 
	C86.001366,70.542480 86.001366,65.238983 86.002930,59.467384 
	C91.558510,57.600502 87.483696,52.538811 90.419296,48.699829 
	C91.422951,50.728992 92.210175,52.321716 92.998459,53.913921 
	C93.605515,55.140087 94.508018,57.495274 94.778328,57.424896 
	C100.389168,55.964020 102.779495,60.336109 105.954086,63.297737 
	C106.295708,63.616440 106.845680,63.711803 107.643837,63.952950 
z"/>
<path fill="#ECECEC" opacity="1.000000" stroke="none" 
	d="
M105.035271,53.294266 
	C105.312775,54.957195 105.393425,56.318008 105.474075,57.678825 
	C104.698936,57.547405 103.923798,57.415985 103.148659,57.284565 
	C103.711914,55.853760 104.275169,54.422958 105.035271,53.294266 
z"/>
<path fill="#EEEFF3" opacity="1.000000" stroke="none" 
	d="
M108.676178,41.988071 
	C108.654564,42.428398 108.486160,42.728168 108.317764,43.027943 
	C108.388298,42.634468 108.458839,42.240993 108.676178,41.988071 
z"/>
<path fill="#1F14DE" opacity="1.000000" stroke="none" 
	d="
M133.128036,66.027962 
	C135.319870,64.379707 137.511703,62.731457 139.061569,61.565960 
	C141.446167,63.605175 143.525192,64.972481 145.051483,66.801117 
	C146.650986,68.717453 147.719986,71.076569 149.021240,74.094154 
	C149.018372,84.962700 149.015121,94.979240 149.003143,105.373985 
	C149.002121,106.509308 149.009857,107.266434 149.015869,108.469780 
	C148.995819,111.575424 148.977463,114.234848 148.959106,116.894272 
	C148.131989,117.277924 147.304886,117.661583 145.738632,118.101295 
	C143.356918,118.113327 141.714340,118.069305 139.790314,117.755325 
	C137.430222,117.334930 135.351578,117.184502 133.198944,116.773758 
	C133.066208,116.342880 133.007462,116.172302 133.011765,115.576103 
	C133.037323,113.431236 132.999817,111.711975 133.019638,109.567047 
	C133.042542,107.425201 133.008118,105.709030 133.027802,103.538124 
	C133.039078,99.723373 132.996246,96.363350 133.003830,92.555542 
	C133.016403,89.407486 132.978531,86.707230 132.988068,83.564545 
	C133.006821,80.749733 132.978180,78.377350 132.992035,75.552429 
	C133.065689,72.075920 133.096863,69.051941 133.128036,66.027962 
z"/>
<path fill="#2C26CA" opacity="1.000000" stroke="none" 
	d="
M151.802353,133.351318 
	C154.670517,133.882660 157.575577,134.275436 160.386078,135.023453 
	C161.320496,135.272141 162.012314,136.432419 162.518555,137.759995 
	C161.470703,140.591141 160.719330,142.838257 159.865479,145.308929 
	C159.309616,146.013351 158.856216,146.494247 158.028534,146.791870 
	C152.133316,146.092255 146.612411,145.575912 140.803650,145.010254 
	C140.325455,144.919891 140.135147,144.878845 139.961304,144.455017 
	C141.400009,140.409775 142.822235,136.747299 144.503876,132.962387 
	C145.282059,132.270538 145.800797,131.701111 146.491730,131.334106 
	C147.105545,132.045502 147.547150,132.554474 148.245331,133.079315 
	C148.673126,133.086700 148.844299,133.078217 149.364853,133.110413 
	C150.410263,133.217834 151.106308,133.284576 151.802353,133.351318 
z"/>
<path fill="#635FE5" opacity="1.000000" stroke="none" 
	d="
M153.994019,90.001442 
	C154.304977,90.122490 154.562180,90.315247 154.882919,91.241310 
	C155.372375,95.572662 155.744553,99.242393 156.116714,102.912132 
	C156.850418,102.897392 157.584122,102.882652 158.317825,102.867920 
	C158.545410,101.577896 158.773010,100.287880 159.366043,99.005081 
	C162.067474,100.855957 164.356613,102.763771 166.758026,104.517868 
	C168.080643,105.483971 169.591965,106.191689 171.018204,107.015900 
	C171.327332,109.913826 170.178207,111.446327 167.126663,110.995209 
	C163.577499,110.470520 161.468323,111.716980 161.578903,116.989777 
	C158.654175,114.616165 156.633545,112.976273 154.246399,110.899979 
	C153.836136,110.283852 153.792404,110.104118 153.804794,109.465668 
	C153.905289,102.671783 153.949646,96.336609 153.994019,90.001442 
z"/>
<path fill="#5C58E8" opacity="1.000000" stroke="none" 
	d="
M171.391693,107.010300 
	C169.591965,106.191689 168.080643,105.483971 166.758026,104.517868 
	C164.356613,102.763771 162.067474,100.855957 159.366058,98.561111 
	C159.000381,95.739235 159.000122,93.368553 158.999847,90.997864 
	C158.999802,90.554024 158.999756,90.110184 159.000153,89.333481 
	C162.103195,88.884529 165.229324,88.709045 165.108200,93.260887 
	C165.096558,93.699142 165.381226,94.439636 165.697479,94.548508 
	C171.260101,96.463539 171.623001,101.998566 173.948303,106.549759 
	C173.258240,106.935890 172.511703,106.970299 171.391693,107.010300 
z"/>
<path fill="#7370E5" opacity="1.000000" stroke="none" 
	d="
M152.020035,133.045166 
	C151.106308,133.284576 150.410263,133.217834 149.270767,132.946289 
	C148.547791,132.848816 148.268265,132.956131 147.988739,133.063446 
	C147.547150,132.554474 147.105545,132.045502 146.388153,130.929016 
	C146.035065,129.206131 145.957748,128.090790 145.921234,126.540657 
	C146.029236,124.067772 146.096420,122.029663 146.163605,119.991562 
	C149.206604,120.019516 151.030624,121.157524 151.199493,124.578102 
	C151.264145,125.887756 152.138611,127.550194 153.182327,128.345154 
	C156.319244,130.734406 154.177277,131.637421 152.020035,133.045166 
z"/>
<path fill="#BFBFD1" opacity="1.000000" stroke="none" 
	d="
M146.095490,119.700943 
	C146.096420,122.029663 146.029236,124.067772 145.592346,126.504128 
	C143.497467,126.233208 141.772308,125.564034 140.188049,124.671936 
	C140.223251,123.295288 140.117554,122.141563 139.985413,120.681496 
	C139.912170,120.175583 139.865356,119.976013 139.841095,119.482193 
	C139.933029,118.800400 140.002396,118.412842 140.071762,118.025284 
	C141.714340,118.069305 143.356918,118.113327 145.406372,118.196854 
	C145.884628,118.627678 145.955994,119.019005 146.095490,119.700943 
z"/>
<path fill="#7975E8" opacity="1.000000" stroke="none" 
	d="
M168.778549,131.155197 
	C167.303696,132.135040 165.791626,132.757004 164.279541,133.378967 
	C163.749954,130.523575 163.220367,127.668182 162.690781,124.812805 
	C163.283813,124.595543 163.876846,124.378273 164.469894,124.161011 
	C165.893707,126.373116 167.317520,128.585220 168.778549,131.155197 
z"/>
<path fill="#3C38D2" opacity="1.000000" stroke="none" 
	d="
M153.748672,109.924393 
	C153.792404,110.104118 153.836136,110.283852 153.944458,110.733475 
	C153.922989,113.933693 154.496567,117.335365 149.361267,116.919739 
	C148.977463,114.234848 148.995819,111.575424 149.345688,108.460045 
	C150.117477,107.997643 150.557755,107.991188 151.054230,108.310249 
	C151.989838,109.065300 152.869263,109.494850 153.748672,109.924393 
z"/>
<path fill="#5A58D8" opacity="1.000000" stroke="none" 
	d="
M159.967957,145.085373 
	C160.719330,142.838257 161.470703,140.591141 162.472046,138.148834 
	C163.157822,139.375046 163.593643,140.796432 164.046875,142.615799 
	C164.065506,143.352722 164.066742,143.691696 163.679169,144.421448 
	C162.182877,144.903275 161.075424,144.994324 159.967957,145.085373 
z"/>
<path fill="#7975E8" opacity="1.000000" stroke="none" 
	d="
M164.426453,120.646248 
	C164.319534,121.055740 164.058975,121.303642 163.798416,121.551544 
	C163.696091,121.443489 163.498718,121.247040 163.505157,121.240128 
	C163.749405,120.977478 164.012360,120.732231 164.426453,120.646248 
z"/>
<path fill="#6B67EB" opacity="1.000000" stroke="none" 
	d="
M62.991264,76.998367 
	C62.974369,74.300949 62.957470,71.603539 62.957283,68.457298 
	C69.004227,68.007332 75.034470,68.006195 81.533173,68.004059 
	C82.001648,68.003052 82.488762,68.044235 82.734154,68.434303 
	C82.659531,70.222633 82.339523,71.620888 81.572891,73.012344 
	C75.081268,74.336487 69.036263,75.667427 62.991264,76.998367 
z"/>
<path fill="#4742DB" opacity="1.000000" stroke="none" 
	d="
M75.632889,50.024353 
	C80.548676,49.270927 82.644554,50.863693 82.083382,55.803482 
	C81.698029,59.195518 81.998787,62.665501 81.979111,66.573601 
	C79.151978,61.923347 76.316559,56.811642 73.612640,51.631290 
	C73.513199,51.440777 74.700287,50.578758 75.632889,50.024353 
z"/>
<path fill="#6561E5" opacity="1.000000" stroke="none" 
	d="
M40.586060,90.994286 
	C41.713531,91.387772 42.387383,91.783836 43.360607,92.194191 
	C43.703743,93.130112 43.747509,94.051735 43.758530,95.311195 
	C43.046417,95.737869 42.367050,95.826706 41.343479,95.957520 
	C36.576878,98.486900 31.569500,99.972153 28.747198,104.958725 
	C27.976597,106.320267 24.863766,106.356140 22.412045,106.998825 
	C24.815607,102.039215 27.044203,96.565376 30.725071,92.363838 
	C32.312584,90.551765 36.905064,91.372276 40.586060,90.994286 
z"/>
<path fill="#5754E1" opacity="1.000000" stroke="none" 
	d="
M158.998016,88.555573 
	C157.678879,88.221031 155.139282,87.023872 155.239578,86.731125 
	C155.927216,84.723969 157.168015,82.906311 158.615326,81.013962 
	C158.999695,83.370682 158.999390,85.741066 158.998016,88.555573 
z"/>
<path fill="#8683ED" opacity="1.000000" stroke="none" 
	d="
M155.052551,171.967133 
	C154.723557,172.429993 154.394547,172.892868 154.031525,173.647491 
	C153.316849,176.341202 152.636169,178.743164 151.676208,181.325287 
	C150.580460,179.586182 149.849594,177.624695 148.929214,175.756592 
	C147.153137,172.151733 145.703812,168.054977 140.355865,168.528015 
	C140.299347,166.085388 140.234177,164.012756 140.347961,161.792938 
	C140.420563,161.121094 140.314209,160.596436 140.165771,159.614899 
	C140.464355,154.791580 140.804993,150.425156 141.463562,146.203400 
	C146.252182,147.664459 150.140167,148.852585 150.215332,155.162170 
	C150.254761,158.469635 153.382645,161.740311 155.098053,165.461853 
	C155.071060,167.919769 155.061798,169.943451 155.052551,171.967133 
z"/>
<path fill="#8C8AED" opacity="1.000000" stroke="none" 
	d="
M140.347214,168.898026 
	C145.703812,168.054977 147.153137,172.151733 148.929214,175.756592 
	C149.849594,177.624695 150.580460,179.586182 151.661163,181.719284 
	C149.466843,189.372177 149.087311,189.536484 141.033112,188.122955 
	C140.002472,187.942062 138.810043,188.682983 137.391434,188.690247 
	C137.475769,187.209412 137.861237,186.035202 138.558990,184.811615 
	C138.634064,183.530838 138.396851,182.299423 138.119827,180.603958 
	C138.095703,176.707764 138.111389,173.275650 138.399902,169.885437 
	C139.230896,169.584244 139.789047,169.241135 140.347214,168.898026 
z"/>
<path fill="#9C9DA2" opacity="1.000000" stroke="none" 
	d="
M138.127075,169.843521 
	C138.111389,173.275650 138.095703,176.707764 138.076614,180.983398 
	C138.131042,182.838272 138.188873,183.849625 138.246719,184.860977 
	C137.861237,186.035202 137.475769,187.209412 137.081848,188.761108 
	C137.073395,189.138596 137.016525,189.394333 136.916275,189.839325 
	C136.527771,190.612534 136.239532,190.940765 135.951294,191.268982 
	C135.951294,191.268982 135.504364,191.102676 135.276718,191.031647 
	C135.824875,178.477905 136.600662,165.995178 137.656052,153.630676 
	C137.999466,159.113770 138.063278,164.478638 138.127075,169.843521 
z"/>
<path fill="#4035E6" opacity="1.000000" stroke="none" 
	d="
M135.993973,191.600449 
	C136.239532,190.940765 136.527771,190.612534 136.919434,189.970276 
	C138.557175,190.641708 140.091476,191.627182 141.078369,192.947571 
	C139.312195,193.956818 138.093414,194.631134 136.874649,195.305450 
	C136.874649,195.305450 136.687759,195.405350 136.338821,195.220047 
	C135.692108,194.797379 135.394348,194.560028 135.096573,194.322662 
	C135.409927,193.525742 135.723282,192.728821 135.993973,191.600449 
z"/>
<path fill="#423CD2" opacity="1.000000" stroke="none" 
	d="
M134.803513,194.276611 
	C135.394348,194.560028 135.692108,194.797379 136.257446,195.152191 
	C135.898758,195.624344 135.272507,195.979050 134.646255,196.333755 
	C134.456741,195.754623 134.267227,195.175507 134.027618,194.283081 
	C134.155167,194.056717 134.332809,194.143631 134.803513,194.276611 
z"/>
<path fill="#423CD2" opacity="1.000000" stroke="none" 
	d="
M137.266479,195.303864 
	C138.093414,194.631134 139.312195,193.956818 140.878036,193.191528 
	C140.036179,193.834473 138.847229,194.568390 137.266479,195.303864 
z"/>
<path fill="#2F2BC6" opacity="1.000000" stroke="none" 
	d="
M153.804794,109.465668 
	C152.869263,109.494850 151.989838,109.065300 151.045502,107.963425 
	C150.324356,106.525993 149.668106,105.760895 149.011871,104.995789 
	C149.015121,94.979240 149.018372,84.962700 149.032837,74.478706 
	C149.691345,73.239784 150.338654,72.468300 150.994202,71.349480 
	C151.720352,71.004379 152.438248,71.006615 153.511673,71.015259 
	C153.897049,77.036148 153.926926,83.050636 153.975403,89.533279 
	C153.949646,96.336609 153.905289,102.671783 153.804794,109.465668 
z"/>
<path fill="#5E5CE0" opacity="1.000000" stroke="none" 
	d="
M158.600067,90.998169 
	C159.000122,93.368553 159.000381,95.739235 159.000641,98.553894 
	C158.773010,100.287880 158.545410,101.577896 158.317825,102.867920 
	C157.584122,102.882652 156.850418,102.897392 156.116714,102.912132 
	C155.744553,99.242393 155.372375,95.572662 155.001328,91.454056 
	C156.068390,91.002945 157.134338,91.000710 158.600067,90.998169 
z"/>
<path fill="#8C8AED" opacity="1.000000" stroke="none" 
	d="
M44.063591,189.020813 
	C43.032482,184.311081 42.001369,179.601364 41.311001,174.409851 
	C43.920746,173.057190 46.189743,172.186340 48.752625,171.202698 
	C47.406963,165.368195 45.928490,158.957840 44.424267,152.435837 
	C48.301144,152.564514 45.693226,149.456894 45.488392,147.567856 
	C48.209190,146.998444 50.596951,146.498734 53.014740,146.446869 
	C53.365658,153.343292 53.633732,159.795258 54.044983,166.238068 
	C54.146423,167.827301 54.713688,169.386810 55.019474,171.304779 
	C54.961739,176.749054 54.951340,181.848541 54.655437,187.190704 
	C50.934490,187.962524 47.499043,188.491669 44.063591,189.020813 
z"/>
<path fill="#8480F2" opacity="1.000000" stroke="none" 
	d="
M52.984711,145.999023 
	C50.596951,146.498734 48.209190,146.998444 45.488392,147.567856 
	C45.693226,149.456894 48.301144,152.564514 44.424267,152.435837 
	C45.928490,158.957840 47.406963,165.368195 48.752625,171.202698 
	C46.189743,172.186340 43.920746,173.057190 41.304230,173.977264 
	C40.491550,173.765366 40.026386,173.504227 39.313992,173.146851 
	C38.874779,172.493378 38.682796,171.936157 38.254566,171.198486 
	C37.307335,164.412857 36.596359,157.807693 35.556217,151.104645 
	C35.369286,149.345001 35.511524,147.683243 36.320404,145.646973 
	C39.999580,145.146347 43.012108,145.020233 46.454544,144.944351 
	C48.899715,144.989761 50.914989,144.984955 52.974030,145.233276 
	C53.006771,145.657242 52.995743,145.828125 52.984711,145.999023 
z"/>
<path fill="#4D43DE" opacity="1.000000" stroke="none" 
	d="
M43.938244,189.332886 
	C47.499043,188.491669 50.934490,187.962524 54.943840,187.349121 
	C55.970757,188.186737 56.423767,189.108612 56.876774,190.030472 
	C55.906090,191.324356 54.935410,192.618240 53.828873,194.199127 
	C50.755543,193.663681 47.783077,192.940994 44.910568,191.933380 
	C44.346504,191.735504 44.168793,190.436234 43.938244,189.332886 
z"/>
<path fill="#6C67ED" opacity="1.000000" stroke="none" 
	d="
M132.949539,76.004967 
	C132.978180,78.377350 133.006821,80.749733 132.619644,83.554077 
	C130.556900,83.263405 128.940720,82.457832 127.257339,81.833481 
	C121.903763,79.847923 116.525154,77.929855 111.100983,75.548805 
	C110.990639,72.770927 110.935539,70.429947 110.880432,68.088974 
	C111.350159,68.064491 111.819885,68.040001 112.642075,68.002884 
	C113.439262,67.994720 113.883987,67.999191 114.895844,68.281128 
	C121.291832,71.040718 127.120682,73.522842 132.949539,76.004967 
z"/>
<path fill="#6460EA" opacity="1.000000" stroke="none" 
	d="
M132.992035,75.552429 
	C127.120682,73.522842 121.291832,71.040718 115.229355,68.281494 
	C119.316673,66.675705 123.680000,65.468399 127.944618,63.978542 
	C131.148026,62.859428 131.785965,61.001152 129.630493,57.939926 
	C128.574982,56.440880 128.723389,54.094135 128.645142,52.072300 
	C132.075790,55.866455 132.456039,60.555653 133.063110,65.611633 
	C133.096863,69.051941 133.065689,72.075920 132.992035,75.552429 
z"/>
<path fill="#2E27D2" opacity="1.000000" stroke="none" 
	d="
M44.406776,69.002022 
	C44.508827,68.093239 45.004093,67.170044 45.789986,66.083229 
	C48.181545,64.371086 50.282478,62.822556 52.670162,61.108406 
	C52.341797,63.045074 51.726681,65.147369 51.064552,67.611237 
	C48.945023,68.311089 46.872505,68.649353 44.406776,69.002022 
z"/>
<path fill="#DBDBFA" opacity="1.000000" stroke="none" 
	d="
M110.667633,68.046738 
	C110.935539,70.429947 110.990639,72.770927 111.038200,75.974686 
	C111.070961,78.555389 111.111267,80.273315 111.091797,82.416580 
	C111.072884,84.556946 111.113754,86.271965 111.020599,88.216614 
	C110.593674,88.632706 110.300751,88.819191 110.007828,89.005676 
	C109.818810,82.198975 109.629791,75.392281 109.731171,68.283318 
	C110.021561,67.981049 110.454834,68.004494 110.667633,68.046738 
z"/>
<path fill="#625FD7" opacity="1.000000" stroke="none" 
	d="
M155.406754,171.882080 
	C155.061798,169.943451 155.071060,167.919769 155.344696,165.163910 
	C156.109924,160.577591 156.610748,156.723434 157.493652,152.916428 
	C158.897446,152.955460 159.919174,152.947342 160.940887,152.939240 
	C160.940887,152.939240 161.005890,153.399857 161.033112,153.629776 
	C160.016525,158.093872 159.052704,162.350143 157.891541,166.551895 
	C157.392258,168.358551 156.483627,170.052078 155.406754,171.882080 
z"/>
<path fill="#2923CF" opacity="1.000000" stroke="none" 
	d="
M46.024639,144.894135 
	C43.012108,145.020233 39.999580,145.146347 36.546417,145.366898 
	C36.082203,145.219330 36.058620,144.977325 36.000961,144.372223 
	C39.011890,144.007401 42.056900,144.005661 45.556759,144.228546 
	C46.011612,144.453186 46.024639,144.894135 46.024639,144.894135 
z"/>
<path fill="#2923CF" opacity="1.000000" stroke="none" 
	d="
M33.137512,140.215607 
	C33.332111,140.124573 33.580509,140.317307 33.884064,140.793182 
	C33.689827,140.884521 33.440521,140.692215 33.137512,140.215607 
z"/>
<path fill="#2724AF" opacity="1.000000" stroke="none" 
	d="
M160.968719,152.583679 
	C159.919174,152.947342 158.897446,152.955460 157.484711,152.542114 
	C157.530075,150.405472 157.966461,148.690308 158.402832,146.975128 
	C158.856216,146.494247 159.309616,146.013351 159.865479,145.308929 
	C161.075424,144.994324 162.182877,144.903275 163.682938,144.676239 
	C164.517609,144.627136 164.959702,144.714020 165.737534,144.841187 
	C164.381042,147.330368 162.688797,149.779236 160.968719,152.583679 
z"/>
<path fill="#878897" opacity="1.000000" stroke="none" 
	d="
M57.034996,190.250427 
	C56.423767,189.108612 55.970757,188.186737 55.229340,187.106445 
	C54.951340,181.848541 54.961739,176.749054 55.293655,171.308777 
	C55.979443,170.932007 56.343712,170.896027 56.707985,170.860062 
	C57.459137,177.535599 58.210285,184.211151 58.900284,191.314362 
	C58.290489,191.318161 57.741856,190.894272 57.034996,190.250427 
z"/>
<path fill="#7975CA" opacity="1.000000" stroke="none" 
	d="
M82.019516,73.019150 
	C82.339523,71.620888 82.659531,70.222633 82.978218,68.447571 
	C83.120712,65.921394 83.264534,63.772022 83.630127,61.305153 
	C83.877922,67.344955 83.903931,73.702263 83.700241,80.663864 
	C83.248138,81.491638 83.087273,81.752998 82.746307,81.822784 
	C82.339561,80.731117 82.174454,79.868912 82.013313,78.578644 
	C82.018028,76.440094 82.018768,74.729622 82.019516,73.019150 
z"/>
<path fill="#AEADAC" opacity="1.000000" stroke="none" 
	d="
M53.911167,139.983582 
	C57.971142,140.271744 61.157997,138.866486 63.765533,135.361053 
	C65.907303,135.400116 67.464600,135.282181 69.021881,135.164230 
	C69.020020,136.218948 69.018158,137.273651 69.030884,138.727112 
	C66.380310,140.218491 63.659756,141.196075 61.068939,142.443192 
	C59.259556,143.314133 57.627769,144.554047 55.420830,145.805313 
	C54.587063,143.982178 54.249115,141.982880 53.911167,139.983582 
z"/>
<path fill="#595789" opacity="1.000000" stroke="none" 
	d="
M53.830627,139.768997 
	C54.249115,141.982880 54.587063,143.982178 55.253944,146.161789 
	C55.652447,148.924210 55.722015,151.506302 55.560661,154.853821 
	C55.758648,158.465897 56.187553,161.312531 56.616455,164.159180 
	C56.668179,166.115143 56.719902,168.071121 56.739807,170.443573 
	C56.343712,170.896027 55.979443,170.932007 55.340988,170.963974 
	C54.713688,169.386810 54.146423,167.827301 54.044983,166.238068 
	C53.633732,159.795258 53.365658,153.343292 53.014740,146.446869 
	C52.995743,145.828125 53.006771,145.657242 52.996445,144.888519 
	C52.317745,143.195480 51.660400,142.100281 51.001961,141.002396 
	C51.000870,140.999710 50.996185,140.994827 50.990189,140.645386 
	C51.000801,139.829590 51.017414,139.363205 51.363117,138.918671 
	C52.340988,138.996933 52.989761,139.053360 53.638535,139.109802 
	C53.638535,139.109802 53.750092,139.554413 53.830627,139.768997 
z"/>
<path fill="#878897" opacity="1.000000" stroke="none" 
	d="
M56.737595,163.742706 
	C56.187553,161.312531 55.758648,158.465897 55.537491,155.291718 
	C56.116402,157.751541 56.487564,160.538879 56.737595,163.742706 
z"/>
<path fill="#9C9DA2" opacity="1.000000" stroke="none" 
	d="
M144.244446,133.084808 
	C142.822235,136.747299 141.400009,140.409775 139.711029,144.523773 
	C139.116074,145.078796 138.787842,145.182312 138.459610,145.285828 
	C138.358994,145.094543 138.258377,144.903259 138.040482,144.410217 
	C135.751053,141.958603 133.578918,139.808777 131.506073,137.364502 
	C132.401047,136.715225 133.196716,136.360382 134.278427,135.959625 
	C134.080444,134.941147 133.596420,133.968552 133.087830,132.750366 
	C133.063263,132.504745 133.032547,132.012039 133.060516,131.810364 
	C133.088470,131.608704 133.075668,131.201721 133.344940,131.154114 
	C133.794373,131.078674 133.974548,131.050873 134.528275,131.020142 
	C136.307281,131.945236 137.712723,132.873276 138.951508,133.691254 
	C140.634735,133.498398 142.439590,133.291595 144.244446,133.084808 
z"/>
<path fill="#4F4E8C" opacity="1.000000" stroke="none" 
	d="
M138.415405,145.572189 
	C138.787842,145.182312 139.116074,145.078796 139.694550,144.906555 
	C140.135147,144.878845 140.325455,144.919891 140.793671,145.261108 
	C141.096252,145.727097 141.120941,145.892899 141.145630,146.058716 
	C140.804993,150.425156 140.464355,154.791580 140.111557,159.923859 
	C140.122604,161.106522 140.145798,161.523315 140.169006,161.940125 
	C140.234177,164.012756 140.299347,166.085388 140.355865,168.528015 
	C139.789047,169.241135 139.230896,169.584244 138.399918,169.885437 
	C138.063278,164.478638 137.999466,159.113770 137.842682,153.278229 
	C137.749695,152.807571 137.798630,152.376923 138.116699,151.951416 
	C138.364212,150.020370 138.293640,148.514832 138.223083,147.009308 
	C138.272461,146.625717 138.321838,146.242142 138.415405,145.572189 
z"/>
<path fill="#9C9DA2" opacity="1.000000" stroke="none" 
	d="
M137.893219,147.140625 
	C138.293640,148.514832 138.364212,150.020370 138.160614,151.739197 
	C137.778748,150.392319 137.671051,148.832123 137.893219,147.140625 
z"/>
<path fill="#DFDFDF" opacity="1.000000" stroke="none" 
	d="
M123.059647,140.199097 
	C123.591255,140.060608 124.148743,140.226883 124.916122,140.598663 
	C124.445847,140.704056 123.765686,140.603973 123.059647,140.199097 
z"/>
<path fill="#DFDFDF" opacity="1.000000" stroke="none" 
	d="
M132.997910,189.002869 
	C132.599503,188.885834 132.201080,188.768799 131.802673,188.651764 
	C132.120972,188.642929 132.439255,188.634109 132.878784,188.812653 
	C133.000000,189.000000 132.997910,189.002869 132.997910,189.002869 
z"/>
<path fill="#DFDFDF" opacity="1.000000" stroke="none" 
	d="
M133.001373,188.998962 
	C133.002762,188.997910 133.423599,189.255295 133.423599,189.255295 
	C133.423599,189.255295 133.101089,189.240875 133.049500,189.121872 
	C132.997910,189.002869 133.000000,189.000000 133.001373,188.998962 
z"/>
<path fill="#DADEE0" opacity="1.000000" stroke="none" 
	d="
M104.006592,128.000473 
	C100.011574,128.340637 96.016548,128.680801 91.669556,128.588440 
	C91.331787,127.596138 91.345978,127.036331 91.464691,126.197861 
	C91.406273,123.987541 91.243340,122.055901 91.526405,120.064285 
	C95.645493,120.384377 99.318588,120.764442 103.018990,121.501823 
	C103.376907,122.941330 103.707512,124.023521 103.938980,125.361824 
	C103.895424,126.412117 103.951012,127.206291 104.006592,128.000473 
z"/>
<path fill="#798488" opacity="1.000000" stroke="none" 
	d="
M104.316391,127.895630 
	C103.951012,127.206291 103.895424,126.412117 104.297592,125.480087 
	C110.149796,127.363449 115.516441,129.464050 120.951981,131.367950 
	C123.219147,132.162079 125.632935,132.537582 128.092041,133.298492 
	C128.415588,133.713394 128.663116,133.879730 129.099854,134.213165 
	C129.581985,134.554886 129.910843,134.676010 130.525085,134.818756 
	C131.190674,134.861496 131.570877,134.882645 132.124771,135.155151 
	C132.863113,135.606201 133.427750,135.805862 133.992386,136.005539 
	C133.196716,136.360382 132.401047,136.715225 131.261093,137.124527 
	C123.002007,134.181961 115.092415,131.170990 107.164299,128.209610 
	C106.376091,127.915192 105.474930,127.923157 104.316391,127.895630 
z"/>
<path fill="#7B898E" opacity="1.000000" stroke="none" 
	d="
M69.389091,135.102524 
	C67.464600,135.282181 65.907303,135.400116 64.098640,135.279312 
	C63.847271,135.040558 63.980938,134.584579 64.389526,134.300140 
	C68.868141,132.371780 72.938171,130.727844 77.123703,129.336945 
	C77.502213,130.080551 77.765228,130.571121 78.028236,131.061707 
	C75.270920,132.388077 72.513611,133.714447 69.389091,135.102524 
z"/>
<path fill="#6A8087" opacity="1.000000" stroke="none" 
	d="
M91.360168,126.476524 
	C91.345978,127.036331 91.331787,127.596138 91.279564,128.574310 
	C88.241661,128.772354 85.241776,128.552032 81.673782,128.199829 
	C81.105675,128.067963 80.817909,127.753075 81.021721,127.568771 
	C81.842392,127.000557 82.459244,126.616646 83.309410,126.175735 
	C83.542717,126.118744 83.988686,125.940331 84.332512,125.940605 
	C85.133919,125.914482 85.591499,125.888092 86.422836,125.933754 
	C88.317780,126.162712 89.838974,126.319618 91.360168,126.476524 
z"/>
<path fill="#6A8087" opacity="1.000000" stroke="none" 
	d="
M78.378098,131.029633 
	C77.765228,130.571121 77.502213,130.080551 77.415474,129.154327 
	C78.667137,128.396805 79.742523,128.074936 80.817909,127.753075 
	C80.817909,127.753075 81.105675,128.067963 81.262520,128.218689 
	C80.522232,129.245468 79.625092,130.121506 78.378098,131.029633 
z"/>
<path fill="#9997F0" opacity="1.000000" stroke="none" 
	d="
M62.558090,116.898407 
	C62.646194,116.179817 62.734299,115.461220 63.120522,114.520477 
	C64.333649,113.962013 65.577415,113.896011 66.111496,113.246483 
	C70.281746,108.174698 75.425659,104.883133 82.005417,104.372330 
	C82.007469,105.494423 82.007156,106.242348 82.004654,107.375122 
	C81.249588,112.716965 77.677040,114.641281 73.423370,116.083282 
	C72.111206,116.528107 71.126678,117.939392 69.589294,118.927444 
	C67.085426,118.594154 64.984390,118.241577 62.838470,117.629395 
	C62.715088,117.212654 62.636589,117.055534 62.558090,116.898407 
z"/>
<path fill="#918FF1" opacity="1.000000" stroke="none" 
	d="
M82.003052,103.998169 
	C75.425659,104.883133 70.281746,108.174698 66.111496,113.246483 
	C65.577415,113.896011 64.333649,113.962013 63.180290,114.162430 
	C62.941940,114.026527 62.752968,114.056290 62.728516,113.792557 
	C62.704063,113.528816 62.573669,113.049294 62.573669,113.049294 
	C62.672401,110.330635 62.771141,107.611969 63.182808,104.627457 
	C64.910004,104.163132 66.418106,104.213440 67.721748,103.721710 
	C72.517372,101.912781 77.245193,99.924095 82.001099,98.431061 
	C82.003464,100.572105 82.003258,102.285141 82.003052,103.998169 
z"/>
<path fill="#736FEC" opacity="1.000000" stroke="none" 
	d="
M81.572891,73.012344 
	C82.018768,74.729622 82.018028,76.440094 81.592522,78.576202 
	C78.775459,79.503067 76.300430,79.765633 74.009598,80.559608 
	C70.267876,81.856438 66.646194,83.499596 62.973877,84.996643 
	C62.958473,82.626106 62.943069,80.255554 62.959465,77.441689 
	C69.036263,75.667427 75.081268,74.336487 81.572891,73.012344 
z"/>
<path fill="#7C78EE" opacity="1.000000" stroke="none" 
	d="
M62.936577,85.395531 
	C66.646194,83.499596 70.267876,81.856438 74.009598,80.559608 
	C76.300430,79.765633 78.775459,79.503067 81.588554,79.004280 
	C82.174454,79.868912 82.339561,80.731117 82.746521,82.186203 
	C82.987946,83.508850 82.987518,84.238625 82.785622,85.094307 
	C82.332092,85.426575 82.155563,85.684601 82.043915,86.002098 
	C82.033264,86.009895 82.018501,86.033073 81.602814,86.024040 
	C75.732788,87.590179 70.278435,89.165352 64.338593,90.880730 
	C64.267746,90.781487 63.629333,89.887215 62.990929,88.992950 
	C62.960377,87.926773 62.929825,86.860596 62.936577,85.395531 
z"/>
<path fill="#8480F0" opacity="1.000000" stroke="none" 
	d="
M62.945763,89.441849 
	C63.629333,89.887215 64.267746,90.781487 64.338593,90.880730 
	C70.278435,89.165352 75.732788,87.590179 81.603745,86.449089 
	C82.013672,88.592033 82.006989,90.300903 81.591461,92.007111 
	C79.300224,92.408684 77.352295,92.625153 75.548592,93.254791 
	C71.320801,94.730644 67.162086,96.404366 62.975403,97.997986 
	C62.950470,95.295570 62.925537,92.593163 62.945763,89.441849 
z"/>
<path fill="#8A88F0" opacity="1.000000" stroke="none" 
	d="
M62.924385,98.424179 
	C67.162086,96.404366 71.320801,94.730644 75.548592,93.254791 
	C77.352295,92.625153 79.300224,92.408684 81.594330,92.434563 
	C82.003548,94.577461 82.001038,96.290253 81.998528,98.003052 
	C77.245193,99.924095 72.517372,101.912781 67.721748,103.721710 
	C66.418106,104.213440 64.910004,104.163132 63.239277,104.176346 
	C62.946331,102.277519 62.909847,100.563942 62.924385,98.424179 
z"/>
<path fill="#8DD5E6" opacity="1.000000" stroke="none" 
	d="
M62.883350,117.889000 
	C64.984390,118.241577 67.085426,118.594154 69.841309,118.968353 
	C70.665253,119.008286 70.834351,119.026581 71.005302,119.280991 
	C71.007149,119.517097 71.012817,119.989304 70.819901,120.241982 
	C69.417778,121.329163 68.208588,122.163666 66.620987,123.007599 
	C64.154549,123.740082 61.878998,124.167351 60.062653,125.318916 
	C59.233761,125.844437 59.310871,127.798965 58.977062,129.105362 
	C57.539494,128.169220 56.101925,127.233086 54.315147,125.807953 
	C52.151321,122.973770 52.048878,120.615852 53.913334,118.243317 
	C54.096191,118.177040 54.279053,118.110756 55.173897,118.019089 
	C58.218372,117.958794 60.550861,117.923897 62.883350,117.889000 
z"/>
<path fill="#C2C6D5" opacity="1.000000" stroke="none" 
	d="
M53.514729,118.179001 
	C52.048878,120.615852 52.151321,122.973770 54.026554,125.655228 
	C52.348412,125.993698 50.609642,125.995903 48.517303,125.961288 
	C47.935398,123.920898 47.707062,121.917313 47.388298,119.565018 
	C47.772221,119.014435 48.246563,118.812576 48.898968,118.421829 
	C50.423393,118.193520 51.769760,118.154099 53.514729,118.179001 
z"/>
<path fill="#3E38DF" opacity="1.000000" stroke="none" 
	d="
M62.838470,117.629395 
	C60.550861,117.923897 58.218372,117.958794 55.451733,117.930061 
	C57.272423,117.561859 59.527264,117.257286 62.170097,116.925560 
	C62.636589,117.055534 62.715088,117.212654 62.838470,117.629395 
z"/>
<path fill="#3E38DF" opacity="1.000000" stroke="none" 
	d="
M62.480064,113.151474 
	C62.573669,113.049294 62.704063,113.528816 62.636166,113.767647 
	C62.341705,113.789627 62.281101,113.538681 62.480064,113.151474 
z"/>
<path fill="#90D1DD" opacity="1.000000" stroke="none" 
	d="
M66.999397,122.998169 
	C68.208588,122.163666 69.417778,121.329163 71.181641,120.244003 
	C72.821777,119.661629 73.907242,119.329910 75.329811,119.005379 
	C76.116356,119.024315 76.565781,119.036064 77.217369,119.375458 
	C79.950104,120.136833 82.512550,120.441750 84.998245,121.056862 
	C86.304581,121.380142 87.476608,122.246193 88.709290,122.867157 
	C87.822556,123.865341 86.935822,124.863518 86.049088,125.861702 
	C85.591499,125.888092 85.133919,125.914482 84.214478,125.801987 
	C83.527115,125.852966 83.301605,126.042847 83.076103,126.232727 
	C82.459244,126.616646 81.842392,127.000557 81.021721,127.568771 
	C79.742523,128.074936 78.667137,128.396805 77.299973,128.901306 
	C72.938171,130.727844 68.868141,132.371780 64.426071,134.065399 
	C63.084274,133.631226 62.114513,133.147369 61.404732,132.180359 
	C65.153481,129.846512 69.096992,128.274704 66.999397,122.998169 
z"/>
<path fill="#84D3DD" opacity="1.000000" stroke="none" 
	d="
M66.620987,123.007599 
	C69.096992,128.274704 65.153481,129.846512 61.348064,131.837494 
	C60.428207,131.202057 59.825005,130.426346 59.099434,129.377991 
	C59.310871,127.798965 59.233761,125.844437 60.062653,125.318916 
	C61.878998,124.167351 64.154549,123.740082 66.620987,123.007599 
z"/>
<path fill="#81838A" opacity="1.000000" stroke="none" 
	d="
M53.949497,138.898834 
	C52.989761,139.053360 52.340988,138.996933 51.301224,138.622208 
	C50.604214,137.553436 50.298195,136.802948 49.992176,136.052475 
	C50.074493,134.653275 50.156811,133.254074 50.298473,130.846100 
	C52.568882,133.754242 56.242489,134.704330 53.949497,138.898834 
z"/>
<path fill="#2E28BC" opacity="1.000000" stroke="none" 
	d="
M49.742889,135.933426 
	C50.298195,136.802948 50.604214,137.553436 50.972130,138.600372 
	C51.017414,139.363205 51.000801,139.829590 50.713799,140.439804 
	C49.597988,138.753418 48.752560,136.923187 47.907131,135.092957 
	C47.907131,135.092957 48.029148,134.974655 48.090569,134.915695 
	C48.599190,135.175949 49.046394,135.495163 49.742889,135.933426 
z"/>
<path fill="#2923CF" opacity="1.000000" stroke="none" 
	d="
M47.474472,135.062622 
	C48.752560,136.923187 49.597988,138.753418 50.719799,140.789246 
	C50.996185,140.994827 51.000870,140.999710 50.986908,141.368820 
	C49.984707,142.494064 48.996475,143.250198 48.008240,144.006317 
	C45.562206,142.677032 43.146370,141.287720 40.660526,140.037598 
	C38.691841,139.047516 36.637287,138.228180 34.302994,137.165253 
	C38.337357,136.342407 42.689587,135.687347 47.474472,135.062622 
z"/>
<path fill="#2E28BC" opacity="1.000000" stroke="none" 
	d="
M47.675476,144.007767 
	C48.996475,143.250198 49.984707,142.494064 50.987999,141.371506 
	C51.660400,142.100281 52.317745,143.195480 52.952675,144.635422 
	C50.914989,144.984955 48.899715,144.989761 46.454544,144.944351 
	C46.024639,144.894135 46.011612,144.453186 46.012001,144.232758 
	C46.455830,144.011307 46.899269,144.010254 47.675476,144.007767 
z"/>
<path fill="#A0A0EC" opacity="1.000000" stroke="none" 
	d="
M77.015213,119.047806 
	C76.565781,119.036064 76.116356,119.024315 75.130219,118.758224 
	C73.396835,118.684219 72.200142,118.864548 71.003456,119.044884 
	C70.834351,119.026581 70.665253,119.008286 70.244141,118.949066 
	C71.126678,117.939392 72.111206,116.528107 73.423370,116.083282 
	C77.677040,114.641281 81.249588,112.716965 82.301132,107.376968 
	C82.994324,106.988121 83.388847,106.982277 83.783371,106.976440 
	C83.586273,110.217499 85.193855,111.159309 88.288834,111.065048 
	C94.872421,110.864517 101.465576,110.978333 108.764610,110.957489 
	C109.474411,110.951675 109.964035,110.933510 110.161316,111.209564 
	C112.570427,113.643837 114.782265,115.802040 117.002296,117.991821 
	C117.010498,118.023399 117.013924,118.087799 116.604614,118.063065 
	C112.767860,118.666656 109.340424,119.294998 105.908813,119.500099 
	C104.593513,118.729820 103.286583,118.102303 101.970558,118.082619 
	C94.990662,117.978241 88.008484,118.026497 80.660309,118.020767 
	C79.200752,118.362373 78.107979,118.705086 77.015213,119.047806 
z"/>
<path fill="#827EEE" opacity="1.000000" stroke="none" 
	d="
M109.972443,89.420670 
	C110.300751,88.819191 110.593674,88.632706 111.384079,88.230484 
	C120.381531,91.401276 128.428040,95.386406 132.973694,103.992859 
	C133.008118,105.709030 133.042542,107.425201 132.728821,109.412628 
	C129.881622,107.250259 127.668159,104.391014 124.818970,102.479187 
	C120.122978,99.328148 115.015900,96.789734 110.082100,93.993088 
	C110.033752,92.607277 109.985405,91.221474 109.972443,89.420670 
z"/>
<path fill="#8985EF" opacity="1.000000" stroke="none" 
	d="
M110.021179,94.407234 
	C115.015900,96.789734 120.122978,99.328148 124.818970,102.479187 
	C127.668159,104.391014 129.881622,107.250259 132.671509,109.838303 
	C132.999817,111.711975 133.037323,113.431236 132.654785,115.527557 
	C130.801239,115.175652 128.675018,114.820564 128.046921,113.656876 
	C124.042915,106.238647 117.265144,102.407043 110.079185,98.997391 
	C110.039543,97.605385 109.999901,96.213387 110.021179,94.407234 
z"/>
<path fill="#8F8CF0" opacity="1.000000" stroke="none" 
	d="
M110.021027,99.394760 
	C117.265144,102.407043 124.042915,106.238647 128.046921,113.656876 
	C128.675018,114.820564 130.801239,115.175652 132.591721,115.953171 
	C133.007462,116.172302 133.066208,116.342880 132.926270,116.786125 
	C132.546448,117.064346 132.365311,117.069901 131.764374,117.062553 
	C128.901733,117.332832 126.458885,117.616013 123.963615,117.560837 
	C119.295937,112.480896 114.680695,107.739311 110.065445,102.997726 
	C110.031258,101.929192 109.997063,100.860664 110.021027,99.394760 
z"/>
<path fill="#9593EF" opacity="1.000000" stroke="none" 
	d="
M110.013611,103.433044 
	C114.680695,107.739311 119.295937,112.480896 123.525154,117.595062 
	C121.090782,117.965172 119.042435,117.962708 116.994095,117.960251 
	C114.782265,115.802040 112.570427,113.643837 110.158127,110.970787 
	C109.957664,110.455948 109.825775,109.996117 109.825775,109.996117 
	C109.871101,107.953537 109.916435,105.910957 110.013611,103.433044 
z"/>
<path fill="#918ED7" opacity="1.000000" stroke="none" 
	d="
M83.841148,106.511871 
	C83.388847,106.982277 82.994324,106.988121 82.303314,106.992119 
	C82.007156,106.242348 82.007469,105.494423 82.005417,104.372330 
	C82.003258,102.285141 82.003464,100.572105 82.001099,98.431061 
	C82.001038,96.290253 82.003548,94.577461 82.003181,92.437210 
	C82.006989,90.300903 82.013672,88.592033 82.019424,86.458122 
	C82.018501,86.033073 82.033264,86.009895 82.247833,85.881065 
	C82.713112,85.541725 82.888008,85.280441 82.987091,84.968391 
	C82.987518,84.238625 82.987946,83.508850 82.988167,82.415665 
	C83.087273,81.752998 83.248138,81.491638 83.655128,81.129662 
	C83.859459,89.343208 83.879189,97.695259 83.841148,106.511871 
z"/>
<path fill="#ECECEC" opacity="1.000000" stroke="none" 
	d="
M88.966614,92.503555 
	C88.988968,96.197273 88.988968,99.457130 88.988968,102.716980 
	C88.101944,102.727333 87.214912,102.737686 86.327888,102.748039 
	C86.327888,98.867310 86.327888,94.986588 86.327888,91.105858 
	C87.200012,91.427132 88.072144,91.748405 88.966614,92.503555 
z"/>
<path fill="#DBDBFA" opacity="1.000000" stroke="none" 
	d="
M109.652542,110.111099 
	C109.825775,109.996117 109.957664,110.455948 109.960846,110.694733 
	C109.964035,110.933510 109.474411,110.951675 109.231239,110.921616 
	C109.103661,110.634178 109.267403,110.412346 109.652542,110.111099 
z"/>
<path fill="#7470ED" opacity="1.000000" stroke="none" 
	d="
M111.151573,81.991234 
	C111.111267,80.273315 111.070961,78.555389 111.093430,76.411591 
	C116.525154,77.929855 121.903763,79.847923 127.257339,81.833481 
	C128.940720,82.457832 130.556900,83.263405 132.572250,83.996506 
	C132.978531,86.707230 133.016403,89.407486 132.656372,92.510437 
	C130.966675,92.225624 129.682388,91.523506 128.381760,90.853142 
	C122.640938,87.894180 116.895401,84.944366 111.151573,81.991234 
z"/>
<path fill="#7B77EE" opacity="1.000000" stroke="none" 
	d="
M111.091797,82.416580 
	C116.895401,84.944366 122.640938,87.894180 128.381760,90.853142 
	C129.682388,91.523506 130.966675,92.225624 132.605942,92.958237 
	C132.996246,96.363350 133.039078,99.723373 133.027817,103.538124 
	C128.428040,95.386406 120.381531,91.401276 111.518097,88.000862 
	C111.113754,86.271965 111.072884,84.556946 111.091797,82.416580 
z"/>
<path fill="#2C25D1" opacity="1.000000" stroke="none" 
	d="
M132.184158,117.075455 
	C132.365311,117.069901 132.546448,117.064346 133.000244,117.046432 
	C135.351578,117.184502 137.430222,117.334930 139.790314,117.755325 
	C140.002396,118.412842 139.933029,118.800400 139.536835,119.323502 
	C136.868042,118.664520 134.526093,117.869987 132.184158,117.075455 
z"/>
<path fill="#2C25D1" opacity="1.000000" stroke="none" 
	d="
M149.003143,105.373985 
	C149.668106,105.760895 150.324356,106.525993 150.989319,107.637924 
	C150.557755,107.991188 150.117477,107.997643 149.347382,108.013824 
	C149.009857,107.266434 149.002121,106.509308 149.003143,105.373985 
z"/>
<path fill="#7C79F0" opacity="1.000000" stroke="none" 
	d="
M141.463562,146.203400 
	C141.120941,145.892899 141.096252,145.727097 141.081543,145.310425 
	C146.612411,145.575912 152.133316,146.092255 158.028534,146.791870 
	C157.966461,148.690308 157.530075,150.405472 157.102646,152.494965 
	C156.610748,156.723434 156.109924,160.577591 155.362427,164.729675 
	C153.382645,161.740311 150.254761,158.469635 150.215332,155.162170 
	C150.140167,148.852585 146.252182,147.664459 141.463562,146.203400 
z"/>
<path fill="#9DA2A4" opacity="1.000000" stroke="none" 
	d="
M144.503876,132.962387 
	C142.439590,133.291595 140.634735,133.498398 138.951508,133.691254 
	C137.712723,132.873276 136.307281,131.945236 134.486359,130.774048 
	C134.070877,130.530899 134.055984,130.032562 134.370697,129.999252 
	C135.471725,129.330017 136.258026,128.694092 137.323486,127.908096 
	C138.417480,126.803635 139.232315,125.849251 140.047150,124.894859 
	C141.772308,125.564034 143.497467,126.233208 145.551529,126.938911 
	C145.957748,128.090790 146.035065,129.206131 146.215942,130.726578 
	C145.800797,131.701111 145.282059,132.270538 144.503876,132.962387 
z"/>
<path fill="#6F6BE8" opacity="1.000000" stroke="none" 
	d="
M148.245331,133.079300 
	C148.268265,132.956131 148.547791,132.848816 148.921402,132.905624 
	C148.844299,133.078217 148.673126,133.086700 148.245331,133.079300 
z"/>
<path fill="#83D6E1" opacity="1.000000" stroke="none" 
	d="
M140.188049,124.671936 
	C139.232315,125.849251 138.417480,126.803635 136.955475,127.844513 
	C133.204758,126.291466 130.101227,124.651924 127.148148,122.685379 
	C128.905426,118.805588 132.030045,118.684479 135.173187,119.155907 
	C136.840164,119.405930 138.402359,120.354568 140.011841,120.987839 
	C140.117554,122.141563 140.223251,123.295288 140.188049,124.671936 
z"/>
<path fill="#88D3E9" opacity="1.000000" stroke="none" 
	d="
M139.985413,120.681496 
	C138.402359,120.354568 136.840164,119.405930 135.173187,119.155907 
	C132.030045,118.684479 128.905426,118.805588 126.764053,122.629349 
	C123.150932,121.934669 120.072342,120.969002 116.998627,119.683609 
	C117.006981,118.938515 117.010452,118.513161 117.013924,118.087799 
	C117.013924,118.087799 117.010498,118.023399 117.002296,117.991821 
	C119.042435,117.962708 121.090782,117.965172 123.577583,117.933411 
	C126.458885,117.616013 128.901733,117.332832 131.764374,117.062561 
	C134.526093,117.869987 136.868042,118.664520 139.514267,119.617737 
	C139.865356,119.976013 139.912170,120.175583 139.985413,120.681496 
z"/>
<path fill="#8C8AED" opacity="1.000000" stroke="none" 
	d="
M140.347961,161.792938 
	C140.145798,161.523315 140.122604,161.106522 140.153625,160.380737 
	C140.314209,160.596436 140.420563,161.121094 140.347961,161.792938 
z"/>
<path fill="#9E9CF1" opacity="1.000000" stroke="none" 
	d="
M138.558990,184.811615 
	C138.188873,183.849625 138.131042,182.838272 138.116425,181.447479 
	C138.396851,182.299423 138.634064,183.530838 138.558990,184.811615 
z"/>
<path fill="#83CDDA" opacity="1.000000" stroke="none" 
	d="
M116.993752,120.003334 
	C120.072342,120.969002 123.150932,121.934669 126.613609,122.956360 
	C130.101227,124.651924 133.204758,126.291466 136.676315,127.994583 
	C136.258026,128.694092 135.471725,129.330017 134.134781,130.124222 
	C133.414658,130.588913 133.245163,130.895309 133.075668,131.201721 
	C133.075668,131.201721 133.088470,131.608704 132.725800,131.811478 
	C131.583344,132.587448 130.803543,133.160629 129.757080,133.809479 
	C129.309143,133.920929 129.127869,133.956757 128.946594,133.992569 
	C128.663116,133.879730 128.415588,133.713394 127.900513,133.007507 
	C126.156410,131.723572 124.765923,130.811356 123.264153,130.153000 
	C120.629379,128.997925 118.789986,127.689766 121.822922,124.758766 
	C119.128555,124.472542 116.830582,124.470680 114.699303,123.873703 
	C113.929031,123.657944 113.558327,122.015732 113.293877,120.992004 
	C114.042740,120.650543 114.503014,120.341507 115.301682,120.023926 
	C116.091293,120.011360 116.542526,120.007347 116.993752,120.003334 
z"/>
<path fill="#9DA2A4" opacity="1.000000" stroke="none" 
	d="
M134.278427,135.959625 
	C133.427750,135.805862 132.863113,135.606201 132.250244,134.907196 
	C132.505463,133.937225 132.808929,133.466599 133.112396,132.995972 
	C133.596420,133.968552 134.080444,134.941147 134.278427,135.959625 
z"/>
<path fill="#8CCADA" opacity="1.000000" stroke="none" 
	d="
M133.087830,132.750366 
	C132.808929,133.466599 132.505463,133.937225 132.076538,134.655823 
	C131.570877,134.882645 131.190674,134.861496 130.457794,134.555878 
	C130.077988,134.092224 130.050858,133.913025 130.023743,133.733826 
	C130.803543,133.160629 131.583344,132.587448 132.697845,132.013153 
	C133.032547,132.012039 133.063263,132.504745 133.087830,132.750366 
z"/>
<path fill="#8CCADA" opacity="1.000000" stroke="none" 
	d="
M133.344940,131.154114 
	C133.245163,130.895309 133.414658,130.588913 133.820068,130.157532 
	C134.055984,130.032562 134.070877,130.530899 134.112793,130.776978 
	C133.974548,131.050873 133.794373,131.078674 133.344940,131.154114 
z"/>
<path fill="#8CCADA" opacity="1.000000" stroke="none" 
	d="
M116.998627,119.683609 
	C116.542526,120.007347 116.091293,120.011360 115.012344,120.046387 
	C113.924835,120.393074 113.465065,120.708755 113.005287,121.024445 
	C113.558327,122.015732 113.929031,123.657944 114.699303,123.873703 
	C116.830582,124.470680 119.128555,124.472542 121.822922,124.758766 
	C118.789986,127.689766 120.629379,128.997925 123.264153,130.153000 
	C124.765923,130.811356 126.156410,131.723572 127.788536,132.812439 
	C125.632935,132.537582 123.219147,132.162079 120.951981,131.367950 
	C115.516441,129.464050 110.149796,127.363449 104.396736,125.223969 
	C103.707512,124.023521 103.376907,122.941330 103.324905,121.386414 
	C104.373337,120.583572 105.143158,120.253456 105.912979,119.923340 
	C109.340424,119.294998 112.767860,118.666656 116.604614,118.063065 
	C117.010452,118.513161 117.006981,118.938515 116.998627,119.683609 
z"/>
<path fill="#1E2860" opacity="1.000000" stroke="none" 
	d="
M105.908813,119.500092 
	C105.143158,120.253456 104.373337,120.583572 103.297592,121.029099 
	C99.318588,120.764442 95.645493,120.384377 91.091248,119.981079 
	C87.149094,119.312523 84.088097,118.667198 81.027092,118.021881 
	C88.008484,118.026497 94.990662,117.978241 101.970558,118.082619 
	C103.286583,118.102303 104.593513,118.729820 105.908813,119.500092 
z"/>
<path fill="#99C7DC" opacity="1.000000" stroke="none" 
	d="
M80.660309,118.020767 
	C84.088097,118.667198 87.149094,119.312523 90.645248,120.041046 
	C91.243340,122.055901 91.406273,123.987541 91.464691,126.197861 
	C89.838974,126.319618 88.317780,126.162712 86.422836,125.933754 
	C86.935822,124.863518 87.822556,123.865341 88.709290,122.867157 
	C87.476608,122.246193 86.304581,121.380142 84.998245,121.056862 
	C82.512550,120.441750 79.950104,120.136833 77.217369,119.375458 
	C78.107979,118.705086 79.200752,118.362373 80.660309,118.020767 
z"/>
<path fill="#9DA2A4" opacity="1.000000" stroke="none" 
	d="
M129.757080,133.809479 
	C130.050858,133.913025 130.077988,134.092224 130.172394,134.534271 
	C129.910843,134.676010 129.581985,134.554886 129.099854,134.213165 
	C129.127869,133.956757 129.309143,133.920929 129.757080,133.809479 
z"/>
<path fill="#99C7DC" opacity="1.000000" stroke="none" 
	d="
M83.309410,126.175735 
	C83.301605,126.042847 83.527115,125.852966 83.870651,125.801712 
	C83.988686,125.940331 83.542717,126.118744 83.309410,126.175735 
z"/>
<path fill="#7975CA" opacity="1.000000" stroke="none" 
	d="
M82.785622,85.094307 
	C82.888008,85.280441 82.713112,85.541725 82.258484,85.873268 
	C82.155563,85.684601 82.332092,85.426575 82.785622,85.094307 
z"/>
<path fill="#99C7DC" opacity="1.000000" stroke="none" 
	d="
M71.005302,119.280991 
	C72.200142,118.864548 73.396835,118.684219 74.793106,118.751038 
	C73.907242,119.329910 72.821777,119.661629 71.374565,119.991325 
	C71.012817,119.989304 71.007149,119.517097 71.005302,119.280991 
z"/>
<path fill="#88D3E9" opacity="1.000000" stroke="none" 
	d="
M113.293877,120.992004 
	C113.465065,120.708755 113.924835,120.393074 114.673950,120.054932 
	C114.503014,120.341507 114.042740,120.650543 113.293877,120.992004 
z"/>
    </Svg>
  );
};

export default Icon;
